<template>
  <!-- Confirmation modal -->
   <!-- Confirmation Modal -->
   <a-modal
        style="top: 20px;"
        centered
        :title="$t('label.succeeded') + '!'"
        :closable="false"
        :maskClosable="false"
        v-model:visible="confirmationModal"
      >
        <template #icon>
          <CheckOutlined style="color: green; font-size: 24px;"/>
        </template>
        <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
        <template #footer style="text-align: right;">
          <a-button id="confirmationOkayButton" type="primary" @click="confirmationModal=false">OK</a-button>
        </template>
      </a-modal>
  <!-- Modal for the StartVM Button Api -->
  <a-modal
    v-model:visible="showStartVMModal"
    :closable="true"
    :footer="null"
    @cancel="closeAction"
    centered>
    <StartVirtualMachine
      :resource="resource"
      :fromVpcSettings="true"
      @close-action="closeAction"
      @reloadVpcSettings="fetchData"
    />
  </a-modal>
  <!-- stopVmModal -->
  <a-modal
    v-model:visible="showStopVmModal"
    :maskClosable="false"
    :loading="loadingStop"
    centered>
    <template #title>
      {{ $t('label.action.stop.instance') }}
      <a
        style="margin-left: 5px"
        :href="$config.docBase + '/' + ('adminguide/virtual_machines.html#stopping-and-starting-vms' || $route.meta.docHelp)"
        target="_blank">
        <render-icon icon="question-circle-outlined"></render-icon>
      </a>
    </template>
    <template #footer style="text-align: right;">
      <a-button :loading="loadingStop" style="margin-right: 4px" @click="closeAction">{{ this.$t('label.cancel') }}</a-button>
      <a-button :loading="loadingStop" type="primary" @click="handleStopVmModal">{{ this.$t('label.ok') }}</a-button>
    </template>
    <a-spin :spinning="modalLoading">
      <span>
        <a-alert type="warning">
          <template #message>{{ $t('message.action.stop.instance') }}</template>
        </a-alert>
        <br>
      </span>
          <template #label>
            {{ $t('label.forced') }}
            <a-tooltip :title="$t('label.force.description')">
              <render-icon icon="info-circle-outlined" style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </template>
          <span>
            <a-switch v-model:checked="switchIsChecked"></a-switch>
          </span>
    </a-spin>
  </a-modal>
  <div class="card-grey" style="position: relative; left: 152px; height: 50px; width: calc(100% - 163px); padding-top: 5px; margin: 0 0 6px 0;" v-if="$route.path.startsWith('/vpc/')">
    <div class='instance-details-modal'>
      <div class='modal-instance-header' style="margin-top: 4px;">
        <div class="modal-instance-header-left">
          <div class="simple-vm-state-container">
            <div v-if="resource.state !== undefined">
              <a-tooltip placement="top" >
                <template #title>
                <span class="simple-vm-state-text">{{ resource.state || resource.status }}</span>
                </template>
                <div class="simple-vm-state running" v-if="resource.state === 'Running'">
                </div>
                <div class="simple-vm-state stopped" v-else-if="resource.state === 'Stopped' || resource.state === 'Error'">

                </div>
              </a-tooltip>
            </div>
            <div v-else-if="resource.podsready">
              <a-tooltip placement="top" v-if="resource.podsready.split('\u0022')[1] === '0' || resource.podsready.split('\u0022')[1]  === '' || resource.podsready.split('\u0022')[1]  === undefined">
                <template #title>
                <span class="simple-vm-state-text">{{ '(' + 0 + '/' + resource.replicacount + 'Replikas)' }}</span>
                </template>
                <div class="simple-vm-state stopped"></div>
              </a-tooltip>
              <a-tooltip placement="top" v-else-if="resource.podsready.split('\u0022')[1] < resource.replicacount">
                  <template #title>
                  <span class="simple-vm-state-text">{{ '(' + resource.podsready.split('\u0022')[1] + '/' + resource.replicacount + 'Replikas)' }}</span>
                  </template>
                  <div class="simple-vm-state stopped"></div>
              </a-tooltip>
              <a-tooltip placement="top" v-else>
                  <template #title>
                  <span class="simple-vm-state-text">{{  'Running (' + resource.podsready.split('\u0022')[1] + '/' + resource.replicacount + 'Replikas)'  }}</span>
                  </template>
                  <div class="simple-vm-state running" ></div>
              </a-tooltip>
            </div>
          </div>
          <div class='modal-instance-header-name' >{{ resource.name }}</div>
        </div>
        <div class="modal-instance-header-right">
          <a-button
              v-if="proxyClientList.filter(x => x.vmid === resource.id)[0] === undefined || resource.hasTsPlus === false || !proxyBoolean"
              :disabled="resource.hasTsPlus === false || !proxyBoolean"
              @click="addProxyClientToVm(resource)"
              :loading="proxyLoading"
              style="margin-left: 1rem;"
              class="icon-not-white">
              <template #icon>
              <RocketOutlined/>
              </template>
              {{$t('label.proxy.add.client') }}
          </a-button>
          <a-button v-else @click="redirectProxyLink(resource)" :loading="proxyLoading" style="margin-left: 1rem;" class="icon-not-white">
              <template #icon>
              <LinkOutlined/>
              </template>
              {{ $t('label.proxy.open.client') }}
          </a-button>
          <span>
          <ActionButton
            :dataView="true"
            :actions="actionsFromResourceType"
            :resource="resource"
            :getVgpuInfoResponse="getVgpuInfoResponse"
            @exec-action="(action) => execAction(action, false)"></ActionButton>
          </span>
          <!-- <router-link :to="{ path: '/vm/' + resource.id }"  target= '_blank' v-if="resource.zoneid" class='row-action-button-console' >
              <InfoCircleOutlined />
          </router-link> -->
          <a-tooltip class='row-action-button-console' arrowPointAtCenter placement='bottomRight' v-if='resource && resource.id'>
            <template #title>
            {{ $t('label.view.console') }}
            </template>
            <Console :resource="resource" :size="'small'" :specialCall="true"></Console>
          </a-tooltip>
          <a-tooltip v-if="resource && resource.id" class='row-action-button-switch-status' arrowPointAtCenter placement='bottomRight'>
              <template #title>
              {{ 'VM Starten/Stoppen' }}
              </template>
              <a-switch v-model:checked="vmRunning" size="small" @click="toggleVmStatus"/>
          </a-tooltip>
          <div class="closeVpcVmElement" @click="closeVmInfoModal"><CloseOutlined :style="{fontSize: '25px', color: 'grey'}"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { api } from '@/api'
import api2 from '@/wpApi/api2'
import Console from '@/components/widgets/Console'
import ActionButton from '@/components/view/ActionButton.vue'
import eventBus from '@/config/eventBus'
// import DestroyVM from '@/views/compute/DestroyVM.vue'
import StartVirtualMachine from '@/views/compute/StartVirtualMachine.vue'

export default {
  name: 'InstanceInfoCard',
  components: {
    ActionButton,
    Console,
    api,
    StartVirtualMachine
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    proxyClientList: {
      type: Array,
      default: () => [],
      required: false
    },
    proxyBoolean: {
      type: Boolean,
      default: false,
      required: false
    },
    fetchProxyClientsLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    proxyDomain: {
      type: String,
      required: false
    },
    vpcOwnerAccUuid: {
      type: String,
      required: false
    }
  },
  computed: {
    vmRunning () {
      if ('state' in this.resource) {
        return this.resource.state === 'Running'
      }
      return false
    },
    actionsFromResourceType () {
      return this.$getActionsFromResourceType('VirtualMachine')
    }
  },
  data () {
    return {
      showStopVmModal: false,
      showStartVMModal: false,
      modalLoading: false,
      loadingStop: false,
      switchIsChecked: false,
      confirmationModal: false,
      confirmationMessage: '',
      proxyLoading: false,
      getVgpuInfoResponse: ''
    }
  },
  mounted () {
    this.fetchVgpu()
  },
  watch: {
    fetchProxyClientsLoading: {
      deep: true,
      handler (newData) {
        this.proxyLoading = newData
      }
    },
    resource: {
      handler (newData) {
        this.fetchVgpu()
      }
    }
  },
  methods: {
    createConfirmationModal (msg) {
      this.confirmationMessage = msg
      this.confirmationModal = true
    },
    redirectProxyLink (vm) {
      if (this.proxyDomain !== undefined) {
        var a = document.createElement('a')
        a.href = 'https://' + this.proxyDomain + '/' + vm.name
        a.setAttribute('target', '_blank')
        a.click()
      } else {
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'There was a Problem generating the Proxy Link'
        })
      }
    },
    // enabling proxy
    async addProxyClientToVm (vm) {
      this.proxyLoading = true
      var params = new FormData()
      params.append('vpcid', this.$route.params.id)
      params.append('accountid', this.vpcOwnerAccUuid)
      params.append('virtualmachineid', vm.id)
      await api2.addProxyClient(params).then(response => {
        if (response.data.status === 'ok') {
          this.createConfirmationModal('VM ' + vm.name + ' wurde zum Proxy hinzugefügt')
          this.eventBus.emit('fetch-proxy-clients')
          // this.fetchProxyClients()
        }
        this.proxyLoading = false
      }).catch(error => {
        this.$notifyError(error)
        this.proxyLoading = false
      })
    },
    fetchVgpu () {
      var vgpuInput = new FormData()
      vgpuInput.append('vmuuid', this.resource.id)
      api2.getAllocatedGpu(vgpuInput).then(response => {
        this.getVgpuInfoResponse = response.data
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'Error fetching GPU State of this VM'
        })
      })
    },
    fetchData () {
      eventBus.emit('vpc-fetch-data')
    },
    execAction (action, isGroupAction) {
      eventBus.emit('exec-action', { action, isGroupAction })
    },
    handleStopVmSubmit () {
      eventBus.emit('stop-vm-submit', this.switchIsChecked)
      this.showStopVmModal = false
    },
    closeAction () {
      this.showAddLicenseModal = false
      this.showEditLicenseModal = false
      this.showDeleteLicenseModal = false
      this.modalInputTagColor = ''
      this.modalInputTagText = ''
      this.modalInputTagType = ''
      this.modalInputTagId = ''
      this.modalLoading = false
      this.showStopVmModal = false
      this.showStartVMModal = false
    },
    closeVmInfoModal () {
      eventBus.emit('close-vmDetails', { destroyBool: false, vmUuid: this.resource.id })
    },
    handleStopVmModal (switchIsChecked) {
      this.closeAction()
      this.modalLoading = true
      api('stopVirtualMachine', {
        id: this.resource.id,
        forced: switchIsChecked
      }).then(response => {
        this.$pollJob({
          jobId: response.stopvirtualmachineresponse.jobid,
          errorMessage: this.$t('message.stop.instance.failed'),
          loadingMessage: `${this.$t('label.action.stop.instance.processing')} ${this.resource.name}`,
          catchMessage: this.$t('error.fetching.async.job.result')
        })
        this.closeAction()
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.fetchData(false)
        this.modalLoading = false
      })
    },
    toggleVmStatus (check) {
      if (check === false) {
        this.showStopVmModal = true
      } else if (check === true) {
        this.showStartVMModal = true
      }
    }
  }
}
</script>
<style lang="scss">
  .closeVpcVmElement {
    font-weight: bold;
    margin-left: 20px;
  }
  .closeVpcVmElement:hover {
    cursor: pointer;
  }
</style>
