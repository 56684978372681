<template>
  <!-- EDIT CONTAINER -->
  <a-modal
    :visible="showEditContainerModal"
    :title="$t('label.container.edit')"
    :maskClosable="false"
    :confirmLoading="loading"
    :loading="loading"
    :width="560"
    @ok="handleModalSubmit"
    @cancel="closeAction"
  >
    <a-form
      v-ctrl-enter="handleModalSubmit"
      :ref="formRefContainerEdit"
      :model="formContainerEdit"
      :rules="rulesContainerEdit"
      @finish.prevent="handleModalSubmit"
      :loading="loading"
      layout="vertical"
    >
      <a-form-item ref="name" name="name" :label="$t('label.name')">
        <a-input
          :placeholder="$t('label.name.container')"
          v-model:value="formContainerEdit.name">
        </a-input>
      </a-form-item>
      <a-form-item ref="ipaddress" name="ipaddress" :label="$t('label.ipaddress')">
        <a-select
          v-model:value="formContainerEdit.ipaddress"
          :placeholder="$t('label.create.ipaddress.description')">
          <a-select-option v-for="item in freeDeploymentIps" :key="item.address" :value="item.address">
            {{ item.address }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- CHANGE TEMPLATE -->
  <a-modal
    :visible="showChangeTemplateModal"
    :title="$t('label.container.template.change')"
    :maskClosable="false"
    :confirmLoading="loading"
    :loading="loading"
    :width="560"
    @ok="handleModalSubmit"
    @cancel="closeAction"
  >
    <a-form
      v-ctrl-enter="handleModalSubmit"
      :ref="formRefContainerEdit"
      :model="formContainerEdit"
      :rules="rulesContainerEdit"
      @finish.prevent="handleModalSubmit"
      :loading="loading"
      layout="vertical"
    >
    <a-form-item ref="containerTemplate" name="containerTemplate" :label="$t('label.container.template')">
      <a-card :tab-list="tabList" :active-tab-key="key" @tabChange="key => onTabChange(key, 'key')" :bordered="false" :tabSize="'small'">
        <a-list class="templateList" :grid="{ gutter: 10, column: 2 }" :data-source="k8sTemplates[key]" :pagination="pagination">
        <template #renderItem="{item}">
          <a-list-item>
            <a-card
              class="containerCard"
              @click="selectContainerTemplate(key + '/' + item.name)"
              :key="item.name"
              hoverable
              :value="key + '/' + item.name"
              v-model:value="formContainerEdit.containerTemplate">
              <template #cover>
                <div
                  :id="key + '/' + item.name"
                  class="containerInner"
                  style="display: flex; width: 250px; flex-direction: row; justify-content: left; align-items: center; padding-top: 5px; height: 100%;">
                  <img v-if="k8sTemplateImages.filter(x => x.file_name === item.logo)[0] === undefined" src="../../assets/icons/docker-logo-black.png" style="height: 40px; width: 50px; margin-right: 5px;"/>
                  <img v-else :src="'data:image/png;base64, ' + k8sTemplateImages.filter(x => x.file_name === item.logo)[0].Content" style="height: 40px; width: 40px; margin-right: 5px;"/>
                  <div v-if="item.name.length>=26">
                    <a-tooltip :title=item.name>{{ item.name.substring(0,25) + '...' }}</a-tooltip>
                  </div>
                  <div v-else>{{ item.name }}</div>
                </div>
              </template>
            </a-card>
          </a-list-item>
        </template>
        <div class="templateInfo" v-if="formContainerEdit.containerTemplate !== undefined && k8sTemplates[key].filter(x => this.formContainerEdit.containerTemplate.includes(x.name))[0] !== undefined">
          <span>
            {{ k8sTemplates[key].filter(x => this.formContainerEdit.containerTemplate.includes(x.name))[0].info }}
          </span>
          <br/>
          Ports:
          <span v-for="ports in k8sTemplates[key].filter(x => this.formContainerEdit.containerTemplate.includes(x.name))[0].ports" :key="ports.port">
            {{ ports.port + ' ' }}
          </span>
        </div>
      </a-list>
    </a-card>
    </a-form-item>
      <a-form-item v-if="resource.licenses[0] !== undefined" ref="licenses" name="licenses" :label="$t('label.licences') + ' (' + (resource.licenses[0] !== undefined ? resource.licenses[0].licenseName : '') +')'">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <a-slider
          style="width:80%"
          :min="this.minLicenses"
          :max="this.maxLicenses"
          v-model:value="formContainerEdit.licenses"
          @change="($event) => updateComputeLicenses($event)"/>
            <a-input-number
            style="width:15%;"
            v-model:value="formContainerEdit.licenses"
            @change="($event) => updateComputeLicenses($event)"/>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- SCALE CONTAINER -->
  <a-modal
    :visible="showScaleContainerModal"
    :title="$t('label.container.scale')"
    :maskClosable="false"
    :confirmLoading="loading"
    :loading="loading"
    :width="560"
    @ok="handleModalSubmit"
    @cancel="closeAction"
  >
    <a-form
      v-ctrl-enter="handleModalSubmit"
      :ref="formRefContainerEdit"
      :model="formContainerEdit"
      :rules="rulesContainerEdit"
      @finish.prevent="handleModalSubmit"
      :loading="loading"
      layout="vertical"
    >
      <a-form-item ref="replikaEditValue" name="replikaEditValue" :label="$t('label.replika')">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <a-slider
            style="width:80%"
            :min="this.minReplika"
            :max="this.maxReplika"
            v-model:value="formContainerEdit.replikaEditValue"
            @change="($event) => updateComputeReplikaEdit($event)"
          />
          <a-input-number
            style="width:15%;"
            v-model:value="formContainerEdit.replikaEditValue"
            @change="($event) => updateComputeReplikaEdit($event)"
          />
        </div>
      </a-form-item>
      <a-form-item ref="cpuNumberEditValue" name="cpuNumberEditValue" :label="$t('label.cpunumber')">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <a-slider
            style="width:80%"
            :min="this.minCpu"
            :max="this.maxCpu"
            v-model:value="formContainerEdit.cpuNumberEditValue"
            @change="($event) => updateComputeCpuNumberEdit($event)"
          />
          <a-input-number
            style="width:15%;"
            v-model:value="formContainerEdit.cpuNumberEditValue"
            @change="($event) => updateComputeCpuNumberEdit($event)"
          />
        </div>
      </a-form-item>
      <a-form-item ref="memoryEditValue" name="memoryEditValue" :label="$t('label.memory.gb')">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <a-slider
            style="width:80%"
            :min="this.resource.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory"
            :max="this.maxMemory"
            v-model:value="formContainerEdit.memoryEditValue"
            @change="($event) => updateComputeMemoryEdit($event)"
          />
          <a-input-number
            style="width:15%;"
            v-model:value="formContainerEdit.memoryEditValue"
            @change="($event) => updateComputeMemoryEdit($event)"
          />
        </div>
      </a-form-item>
      <a-form-item ref="diskEditValue" name="diskEditValue" :label="$t('label.disk.gb')">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <a-slider
            style="width:80%"
            :min="this.minDisk"
            :max="this.maxDisk"
            v-model:value="formContainerEdit.diskEditValue"
            @change="($event) => updateComputeDiskEdit($event)"
          />
          <a-input-number
            style="width:15%;"
            v-model:value="formContainerEdit.diskEditValue"
            @change="($event) => updateComputeDiskEdit($event)"
          />
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
  <DeleteContainerDeployment
    v-if="showDeleteContainerModal"
    :showModal="showDeleteContainerModal"
    :deleteDeploymentId="resource.deploymentid"
    :deleteDeploymentName="resource.name"
    @close-action="closeAction"
    @fetch-data="fetchData"/>
	<span class='row-action-button' style="justify-content: flex-end;">
    <a-dropdown>
      <span class='row-action-button-dropdown action'>
        ...
      </span>
      <template #overlay>
        <a-menu class='user-menu-wrapper'>
          <template v-for="(action, index) in actions" :key="index">
            <a-menu-item @click='openModal(action.action)'>
              <render-icon :icon='action.icon' />
              {{ $t(action.label) }}
            </a-menu-item>
          </template>
        </a-menu>
      </template>
		</a-dropdown>
	</span>
</template>

<script>
import { MoreOutlined, ArrowsAltOutlined, CameraOutlined } from '@ant-design/icons-vue'
import eventBus from '@/config/eventBus'
import api2 from '@/wpApi/api2'
import DeleteContainerDeployment from './DeleteContainerDeployment.vue'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'DeploymentActionButton',
  components: {
    MoreOutlined,
    ArrowsAltOutlined,
    CameraOutlined,
    DeleteContainerDeployment
  },
  setup () {
    const pagination = {
      onChange: (page) => {
        formContainerEdit.value.containerTemplate = undefined
        checkedRadio.value = undefined
      },
      pageSize: 8,
      size: 'small'
    }
    const tabList = [
      {
        key: 'public',
        tab: 'Public Templates'
      },
      {
        key: 'private',
        tab: 'Private Templates'
      }
    ]
    const onTabChange = (value, type) => {
      formContainerEdit.value.containerTemplate = undefined
      checkedRadio.value = undefined
      if (type === 'key') {
        key.value = value
      }
    }
    var checkedRadio = ref(undefined)
    const key = ref('public')
    const formContainerEdit = ref({})
    return {
      pagination,
      onTabChange,
      tabList,
      key,
      checkedRadio,
      formContainerEdit
    }
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    vpcOwnerAccUuid: {
      type: String,
      required: true
    },
    vpcResource: {
      type: Object,
      required: true
    },
    k8sTemplateImages: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      actions: [
        {
          label: 'label.container.edit',
          icon: 'SettingOutlined',
          action: 'EditContainer'
        },
        {
          label: 'label.container.template.change',
          icon: 'CameraOutlined',
          action: 'ChangeTemplate'
        },
        {
          label: 'label.container.scale',
          icon: 'ArrowsAltOutlined',
          action: 'ScaleContainer'
        },
        {
          label: 'label.container.destroy',
          icon: 'DeleteOutlined',
          action: 'DestroyContainer'
        }
      ],
      showEditContainerModal: false,
      showChangeTemplateModal: false,
      showScaleContainerModal: false,
      showDeleteContainerModal: false,
      freeDeploymentIps: [],
      loading: false,
      pageSize: 20,
      items: [],
      k8sTemplates: [],
      minReplika: 1,
      maxReplika: 5,
      minCpu: 1,
      maxCpu: 20,
      minMemory: 1,
      maxMemory: 64,
      minDisk: 1,
      maxDisk: 1000,
      minLicenses: 1,
      maxLicenses: 200
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchNetworks()
  },
  methods: {
    initForm () {
      this.formRefContainerEdit = ref()
      this.formContainerEdit = reactive({
        name: undefined,
        ipaddress: undefined,
        replikaEditValue: 1,
        cpuNumberEditValue: 1,
        containerTemplate: undefined,
        licenses: undefined,
        memoryEditValue: 1,
        diskEditValue: 1
      })
      this.rulesContainerEdit = reactive({
        name: [{ required: true, message: this.$t('label.required') }],
        ipaddress: [{ required: true, message: this.$t('label.required') }],
        replikaEditValue: [{ required: true, message: this.$t('label.required') }],
        cpuNumberEditValue: [{ required: true, message: this.$t('label.required') }],
        memoryEditValue: [{ required: true, message: this.$t('label.required') }],
        diskEditValue: [{ required: true, message: this.$t('label.required') }],
        licenses: [{ required: true, message: this.$t('label.required') }]
      })
    },
    async openModal (action) {
      await this.fetchDeployment()
      if (action === 'EditContainer') {
        await this.listFreeIps()
        this.showEditContainerModal = true
      }
      if (action === 'ChangeTemplate') {
        await this.getK8sTemplateList()
        this.showChangeTemplateModal = true
      }
      if (action === 'ScaleContainer') {
        this.showScaleContainerModal = true
      }
      if (action === 'DestroyContainer') {
        this.showDeleteContainerModal = true
      }
    },
    async fetchDeployment () {
      this.formContainerEdit.ipaddress = this.resource.ipaddress
      this.formContainerEdit.name = this.resource.name
      this.formContainerEdit.containerTemplate = undefined
      this.formContainerEdit.cpuNumberEditValue = parseInt(this.resource.cpuLimit)
      this.formContainerEdit.replikaEditValue = parseInt(this.resource.replicacount)
      this.formContainerEdit.memoryEditValue = parseInt(this.resource.memLimit)
      this.formContainerEdit.diskEditValue = parseInt(this.resource.disksize)
      this.formContainerEdit.licenses = parseInt((this.resource.licenses[0] !== undefined ? this.resource.licenses[0].count : 1))
    },
    fetchData () {
      eventBus.emit('vpc-fetch-deployments')
    },
    closeAction () {
      this.showEditContainerModal = false
      this.showChangeTemplateModal = false
      this.showScaleContainerModal = false
      this.showDeleteContainerModal = false
    },
    async listFreeIps () {
      var params = new FormData()
      params.append('networkuuid', this.resource.networkid)
      await api2.listFreeIps(params).then(response => {
        this.freeDeploymentIps = response.data.available
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async handleModalSubmit () {
      this.formRefContainerEdit.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formContainerEdit)
        var editContainerParams = new FormData()
        editContainerParams.append('accountId', this.vpcOwnerAccUuid)
        if (values.containerTemplate) {
          editContainerParams.append('deploymentPath', values.containerTemplate)
        }
        editContainerParams.append('deploymentId', this.resource.deploymentid)
        editContainerParams.append('name', values.name)
        editContainerParams.append('replicaCount', values.replikaEditValue)
        editContainerParams.append('cpuLimit', values.cpuNumberEditValue)
        editContainerParams.append('memLimit', values.memoryEditValue)
        editContainerParams.append('diskSize', values.diskEditValue)
        editContainerParams.append('networkId', this.resource.networkid)
        editContainerParams.append('ipAddress', values.ipaddress)
        editContainerParams.append('gateway', this.vpcResource.network.filter(x => x.id === this.resource.networkid)[0].gateway)
        editContainerParams.append('count', this.resource.licenses[0] !== undefined ? values.licenses : 'null')
        editContainerParams.append('tagId', this.resource.licenses[0] !== undefined ? this.resource.licenses[0].licenseId : 'null')
        await api2.updateK8sDeployment(editContainerParams).then(response => {
          this.closeAction()
          this.fetchData()
          this.loading = false
          this.$notification.success({
            message: this.$t('message.success.update.container')
          })
          this.fetchData()
        }).catch(error => {
          console.log(error)
          this.closeAction()
          this.fetchData()
          this.loading = false
          this.$notification.error({
            message: this.$t('message.error.update.container')
          })
        })
      })
    },
    fetchNetworks () {
      this.network = this.vpcResource.network.filter(x => x.id === this.resource.networkid)[0]
    },
    async getK8sTemplateList () {
      this.loading = true
      var params = new FormData()
      params.append('accountuuid', this.vpcOwnerAccUuid)
      await api2.getK8sTemplateList(params).then(response => {
        this.k8sTemplates = response.data
        if (this.k8sTemplates.private === null || this.k8sTemplates.private === undefined) {
          this.k8sTemplates.private = []
        } else {
          var privateFilterTemplates = this.k8sTemplates.private.filter(template => template.group === this.resource.group)
          if (privateFilterTemplates) {
            this.k8sTemplates.private = privateFilterTemplates
          } else {
            this.k8sTemplates.private = []
          }
        }
        if (this.k8sTemplates.public === null || this.k8sTemplates.public === undefined) {
          this.k8sTemplates.public = []
        } else {
          var publicFilterTemplates = this.k8sTemplates.public.filter(template => template.group === this.resource.group)
          if (publicFilterTemplates) {
            this.k8sTemplates.public = publicFilterTemplates
          } else {
            this.k8sTemplates.private = []
          }
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$notifyError(error)
      })
    },
    selectContainerTemplate (value) {
      var element = document.getElementById(value)
      element.classList.add('checked')
      this.formContainerEdit.containerTemplate = value
      if (this.checkedRadio) {
        var element2 = document.getElementById(this.checkedRadio)
        element2.classList.remove('checked')
      }
      element.classList.add('checked')
      this.checkedRadio = value
    },
    updateComputeLicenses (value) {
      if (value === '' || value < this.minLicenses) {
        this.formContainerEdit.licenses = this.minLicenses
      } else if (value > this.maxLicenses) {
        this.formContainerEdit.licenses = this.maxLicenses
      }
    },
    updateComputeReplikaEdit (value) {
      if (value === '' || value < this.minReplika) {
        this.formContainerEdit.replikaEditValue = this.minReplika
      } else if (value > this.maxReplika) {
        this.formContainerEdit.replikaEditValue = this.maxReplika
      }
    },
    updateComputeCpuNumberEdit (value) {
      if (value === '' || value < this.minCpu) {
        this.formContainerEdit.cpuNumberEditValue = this.minCpu
      } else if (value > this.maxCpu) {
        this.formContainerEdit.cpuNumberEditValue = this.maxCpu
      }
    },
    updateComputeMemoryEdit (value) {
      if (value === '' || value < (this.resource.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory)) {
        this.formContainerEdit.memoryEditValue = this.resource.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory
      } else if (value > this.maxMemory) {
        this.formContainerEdit.memoryEditValue = this.maxMemory
      }
    },
    updateComputeDiskEdit (value) {
      if (value === '' || value < this.minDisk) {
        this.formContainerEdit.diskEditValue = this.minDisk
      } else if (value > this.maxDisk) {
        this.formContainerEdit.diskEditValue = this.maxDisk
      }
    }
  }
}
</script>

<style>
:deep(.ant-card-head) {
    background: white;
  }
  .ant-card-cover {
    display: flex;
  }
  .containerCard {
    width: 250px;
    height: 50px;
    background-color: white;
    margin-bottom: -8px;
    padding: 5px;
    user-select: none;
    display: flex;
  }
  .containerCard ant-card-body {
    padding: 0;
  }
  .checked {
    /* border: 3px solid rgba(61, 124, 175, 1); */
    border: 3px solid rgba(0, 0, 0, 0.15);
  }
  .templateList {
    padding-top: 5px;
    background-color: white;
  }
  .templateInfo {
    margin-top: -5px;
  }
  :deep(.ant-card-body) {
    padding: 0px;
  }
</style>
