// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <resource-layout>
    <template #left>
      <slot name="info-card">
        <instance-info-card v-if="$route.path.startsWith('/vm/')" :resource="resource" :loading="loading" />
        <info-card v-else :resource="resource" :loading="loading" />
      </slot>
    </template>
    <template #right>
      <!-- WP Implementation -->
      <!-- VPC Overview Settings -->
      <a-card class='vpc-card' v-if="$route.path.startsWith('/vpc/')" style='background: #fff; border: none'>
        <VpcSettings
          :resource="vpcResourceInput"
          :loading="loading"
          :actions="actions"
          :refreshCalled="refreshCalled"
          :key="forceReloadVpc"
          @force-reload="forceReload"/>
      </a-card>
      <a-card v-else-if="$route.path.startsWith('/vpn/')">
        <!-- VPN Settings -->
        <Vpn
          :resource="resource"
          :loading="loading"
          :actions="actions"/>
      </a-card>
      <a-card v-else-if="$route.path.startsWith('/firewall/')">
        <!-- Firewall Settings -->
        <VpcTab
          :resource="resource"
          :loading="loading"
          :actions="actions"/>
      </a-card>

      <a-card class='no-padding no-outline  new-tab-menu' v-else-if="$route.path.startsWith('/vm/')">
        <!-- Instance Settings -->
        <InstanceTab
          :resource="resource"
          :loading="loading"
          :actions="actions"/>
      </a-card>
      <a-card class='no-padding no-outline  new-tab-menu' v-else-if="$route.path.startsWith('/volume/')">
        <!-- Instance Settings -->
        <VolumeTab
          :resource="resource"
          :loading="loading"
          :actions="actions"/>
      </a-card>
      <!-- Only Appears when the route path does not start with vpc, firewall or vpn -->
      <a-card
        v-if="!$route.path.startsWith('/vm/') && !$route.path.startsWith('/volume/') && !$route.path.startsWith('/vpn/') && !$route.path.startsWith('/firewall/') && !$route.path.startsWith('/vpc/')"
        class="spin-content "
        :class='tabStyle'
        :loading="loading"
        :bordered="true"
        style="width:100%">
        <keep-alive v-if="tabs.length === 1">
          <component
            :is="tabs[0].component"
            :resource="resource"
            :loading="loading"
            :tab="tabs[0].name" />
        </keep-alive>
        <a-tabs
          v-else
          :tabPosition="tabPosition"
          style="width: 100%"
          :animated="false"
          :activeKey="activeTab || tabs[0].name"
          @change="onTabChange" >
          <template v-for="tab in tabs" :key="tab.name">
            <a-tab-pane
              :key="tab.name"
              :tab="$t('label.' + tab.name)"
              v-if="showTab(tab)">
              <keep-alive>
                <template v-if='showGreyCard(tab.name)'>
                  <div class='card-grey'>
                    <component
                      v-if="tab.resourceType"
                      :is="tab.component"
                      :resource="resource"
                      :resourceType="tab.resourceType"
                      :loading="loading"
                      :tab="activeTab" />
                    <component v-else :is="tab.component" :resource="resource" :loading="loading" :tab="activeTab" />
                  </div>
                </template>
                <template v-else>
                    <component
                      v-if="tab.resourceType"
                      :is="tab.component"
                      :resource="resource"
                      :resourceType="tab.resourceType"
                      :loading="loading"
                      :tab="activeTab" />
                    <component v-else :is="tab.component" :resource="resource" :loading="loading" :tab="activeTab" />
                </template>
              </keep-alive>
            </a-tab-pane>
          </template>
        </a-tabs>
      </a-card>
    </template>
  </resource-layout>
</template>

<script>
import DetailsTab from '@/components/view/DetailsTab'
import InfoCard from '@/components/view/InfoCard'
import ResourceLayout from '@/layouts/ResourceLayout'
import { api } from '@/api'
import { mixinDevice } from '@/utils/mixin.js'
// WP Implementation
import VpcSettings from '@/components/view/VpcSettings.vue'
import Vpn from '@/components/view/Vpn'
import VpcTab from '@/views/network/VpcTab'
import InstanceTab from '@/views/compute/InstanceTab.vue'
import InstanceInfoCard from '@/views/compute/InstanceInfoCard.vue'
import VolumeTab from '@/views/compute/VolumeTab.vue'

export default {
  name: 'ResourceView',
  components: {
    InstanceInfoCard,
    InstanceTab,
    InfoCard,
    ResourceLayout,
    VpcSettings,
    Vpn,
    VpcTab,
    VolumeTab
  },
  // /WP Implementation
  mixins: [mixinDevice],
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    refreshCalled: {
      type: Number,
      required: false
    },
    tabs: {
      type: Array,
      default: function () {
        return [{
          name: 'details',
          component: DetailsTab
        }]
      }
    },
    historyTab: {
      type: String,
      default: ''
    },
    // WP Implementation
    actions: {
      type: Array,
      default: () => []
    }
    // /WP Implementation
  },
  data () {
    return {
      activeTab: '',
      networkService: null,
      projectAccount: null,
      forceReloadVpc: 0,
      vpcResourceInput: undefined,
      fallbackVpcResource: undefined
    }
  },
  watch: {
    resource: {
      deep: true,
      handler (newItem, oldItem) {
        this.vpcResourceInput = this.resource
        // always set the VPC resource to a actual VPC - this is changed by the fact that we added actionButtons to the VPC view that change resource from Autogen.vue
        if (newItem.vpcofferingid && newItem.vpcofferingname && newItem.cidr) {
          this.fallbackVpcResource = newItem
          this.vpcResourceInput = newItem
        } else if (oldItem.vpcofferingid && oldItem.vpcofferingname && oldItem.cidr) {
          this.fallbackVpcResource = oldItem
          this.vpcResourceInput = oldItem
        } else {
          // if old AND new resource are not a VPC, set the vpcResourceInput to an old vpc
          if (this.fallbackVpcResource) {
            this.vpcResourceInput = this.fallbackVpcResource
          }
        }
        if (newItem.id === oldItem.id) {
          console.log('return called in ResourceView because Resources are the same')
          return
        }
        if (this.resource.associatednetworkid) {
          api('listNetworks', { id: this.resource.associatednetworkid, listall: true }).then(response => {
            if (response && response.listnetworksresponse && response.listnetworksresponse.network) {
              this.networkService = response.listnetworksresponse.network[0]
            } else {
              this.networkService = {}
            }
          })
        }
      }
    },
    '$route.fullPath': function () {
      this.setActiveTab()
    },
    tabs: {
      handler () {
        this.setActiveTab()
      }
    }
  },
  created () {
    const self = this
    this.setActiveTab()
    window.addEventListener('popstate', function () {
      self.setActiveTab()
    })
  },
  computed: {
    tabStyle () {
      if (this.$route.path.startsWith('/volume/')) {
        return 'no-padding no-outline  new-tab-menu'
      }
      return 'resource-card-grey'
    },
    tabPosition () {
      if (this.$route.path.startsWith('/volume/')) {
        return this.device === 'mobile' ? 'top' : 'left'
      }
      return 'top'
    }
  },
  methods: {
    showGreyCard (name) {
      if (name === 'overview' || name === 'comments') {
        return false
      }
      return true
    },
    forceReload () {
      this.forceReloadVpc += 1
    },
    onTabChange (key) {
      this.activeTab = key
      const query = Object.assign({}, this.$route.query)
      query.tab = key
      this.$route.query.tab = key
      history.pushState(
        {},
        null,
        '#' + this.$route.path + '?' + Object.keys(query).map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
          )
        }).join('&')
      )
      this.$emit('onTabChange', key)
    },
    showTab (tab) {
      if (this.networkService && this.networkService.service && tab.networkServiceFilter) {
        return tab.networkServiceFilter(this.networkService.service)
      } else if ('show' in tab) {
        return tab.show(this.resource, this.$route, this.$store.getters.userInfo)
      } else {
        return true
      }
    },
    setActiveTab () {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab
        return
      }
      if (!this.historyTab || !this.$route.meta.tabs || this.$route.meta.tabs.length === 0) {
        this.activeTab = this.tabs[0].name
        return
      }
      const tabIdx = this.$route.meta.tabs.findIndex(tab => tab.name === this.historyTab)
      if (tabIdx === -1) {
        this.activeTab = this.tabs[0].name
      } else {
        this.activeTab = this.historyTab
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
