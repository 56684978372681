<template>
    <a-spin :spinning="loading">
      <div>
        <a-modal
        :visible="showModal"
        :title="$t('label.container.deployment.edit')"
        :maskClosable="false"
        :confirmLoading="loading"
        :loading="loading"
        :width="560"
        @ok="handleEditContainerSubmit"
        @cancel="closeAction"
      >
        <!-- Edit Container Deployment -->
        <a-form
          v-ctrl-enter="handleEditContainerSubmit"
          :ref="formRefContainerEdit"
          :model="formContainerEdit"
          :rules="rulesContainerEdit"
          @finish.prevent="handleEditContainerSubmit"
          :loading="loading"
          layout="vertical"
        >
          <a-form-item ref="name" name="name" :label="$t('label.name')">
            <a-input
              :placeholder="$t('label.name.container')"
              v-model:value="formContainerEdit.name">
            </a-input>
          </a-form-item>
          <a-form-item ref="ipaddress" name="ipaddress" :label="$t('label.ipaddress')">
            <a-select
              v-model:value="formContainerEdit.ipaddress"
              :placeholder="$t('label.create.ipaddress.description')">
              <a-select-option v-for="item in freeDeploymentIps" :key="item.address" :value="item.address">
                {{ item.address }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item ref="containerTemplate" name="containerTemplate" :label="$t('label.container.template')">
          <a-card :tab-list="tabList" :active-tab-key="key" @tabChange="key => onTabChange(key, 'key')" :bordered="false" :tabSize="'small'">
            <a-list class="templateList" :grid="{ gutter: 10, column: 2 }" :data-source="k8sTemplates[key]" :pagination="pagination">
            <template #renderItem="{item}">
              <a-list-item>
                <a-card
                  class="containerCard"
                  @click="selectContainerTemplate(key + '/' + item.name)"
                  :key="item.name"
                  hoverable
                  :value="key + '/' + item.name"
                  v-model:value="formContainerEdit.containerTemplate">
                  <template #cover>
                    <div
                      :id="key + '/' + item.name"
                      class="containerInner"
                      style="display: flex; width: 250px; flex-direction: row; justify-content: left; align-items: center; padding-top: 5px; height: 100%;">
                      <img v-if="templateImageInfo.filter(x => x.file_name === item.logo)[0] === undefined" src="../../assets/icons/docker-logo-black.png" style="height: 40px; width: 50px; margin-right: 5px;"/>
                      <img v-else :src="'data:image/png;base64, ' + templateImageInfo.filter(x => x.file_name === item.logo)[0].Content" style="height: 40px; width: 40px; margin-right: 5px;"/>
                      <div v-if="item.name.length>=26">
                        <a-tooltip :title=item.name>{{ item.name.substring(0,25) + '...' }}</a-tooltip>
                      </div>
                      <div v-else>{{ item.name }}</div>
                    </div>
                  </template>
                </a-card>
              </a-list-item>
            </template>
            <div class="templateInfo" v-if="formContainerEdit.containerTemplate !== undefined && k8sTemplates[key].filter(x => this.formContainerEdit.containerTemplate.includes(x.name))[0] !== undefined">
              <span>
                {{ k8sTemplates[key].filter(x => this.formContainerEdit.containerTemplate.includes(x.name))[0].info }}
              </span>
              <br/>
              Ports:
              <span v-for="ports in k8sTemplates[key].filter(x => this.formContainerEdit.containerTemplate.includes(x.name))[0].ports" :key="ports.port">
                {{ ports.port + ' ' }}
              </span>
            </div>
          </a-list>
        </a-card>
        </a-form-item>
          <a-form-item v-if="deployment.licenses[0] !== undefined" ref="licenses" name="licenses" :label="$t('label.licences') + ' (' + (deployment.licenses[0] !== undefined ? deployment.licenses[0].licenseName : '') +')'">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
              style="width:80%"
              :min="this.minLicenses"
              :max="this.maxLicenses"
              v-model:value="formContainerEdit.licenses"
              @change="($event) => updateComputeLicenses($event)"/>
                <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.licenses"
                @change="($event) => updateComputeLicenses($event)"/>
            </div>
          </a-form-item>
          <a-form-item ref="replikaEditValue" name="replikaEditValue" :label="$t('label.replika')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="this.minReplika"
                :max="this.maxReplika"
                v-model:value="formContainerEdit.replikaEditValue"
                @change="($event) => updateComputeReplikaEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.replikaEditValue"
                @change="($event) => updateComputeReplikaEdit($event)"
              />
            </div>
          </a-form-item>
          <a-form-item ref="cpuNumberEditValue" name="cpuNumberEditValue" :label="$t('label.cpunumber')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="this.minCpu"
                :max="this.maxCpu"
                v-model:value="formContainerEdit.cpuNumberEditValue"
                @change="($event) => updateComputeCpuNumberEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.cpuNumberEditValue"
                @change="($event) => updateComputeCpuNumberEdit($event)"
              />
            </div>
          </a-form-item>
          <a-form-item ref="memoryEditValue" name="memoryEditValue" :label="$t('label.memory.gb')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="deployment.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory"
                :max="this.maxMemory"
                v-model:value="formContainerEdit.memoryEditValue"
                @change="($event) => updateComputeMemoryEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.memoryEditValue"
                @change="($event) => updateComputeMemoryEdit($event)"
              />
            </div>
          </a-form-item>
          <a-form-item ref="diskEditValue" name="diskEditValue" :label="$t('label.disk.gb')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="this.minDisk"
                :max="this.maxDisk"
                v-model:value="formContainerEdit.diskEditValue"
                @change="($event) => updateComputeDiskEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.diskEditValue"
                @change="($event) => updateComputeDiskEdit($event)"
              />
            </div>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    </a-spin>
</template>

<script>

// import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'EditContainerDeployment',
  components: {
  },
  setup () {
    const pagination = {
      onChange: (page) => {
        formContainerEdit.value.containerTemplate = undefined
        checkedRadio.value = undefined
      },
      pageSize: 8,
      size: 'small'
    }
    const tabList = [
      {
        key: 'public',
        tab: 'Public Templates'
      },
      {
        key: 'private',
        tab: 'Private Templates'
      }
    ]
    const onTabChange = (value, type) => {
      formContainerEdit.value.containerTemplate = undefined
      checkedRadio.value = undefined
      if (type === 'key') {
        key.value = value
      }
    }
    var checkedRadio = ref(undefined)
    const key = ref('public')
    const formContainerEdit = ref({})
    return {
      pagination,
      onTabChange,
      tabList,
      key,
      checkedRadio,
      formContainerEdit
    }
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    },
    deployment: {
      type: Object,
      required: true,
      default: () => ({})
    },
    gateway: {
      required: true
    },
    accountUuid: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      freeDeploymentIps: [],
      loading: false,
      pageSize: 20,
      items: [],
      k8sTemplates: [],
      templateImageInfo: [],
      minReplika: 1,
      maxReplika: 5,
      minCpu: 1,
      maxCpu: 20,
      minMemory: 1,
      maxMemory: 64,
      minDisk: 1,
      maxDisk: 1000,
      minLicenses: 1,
      maxLicenses: 200
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRefContainerEdit = ref()
      this.formContainerEdit = reactive({
        name: undefined,
        ipaddress: undefined,
        replikaEditValue: 1,
        cpuNumberEditValue: 1,
        containerTemplate: undefined,
        licenses: undefined,
        memoryEditValue: 1,
        diskEditValue: 1
      })
      this.rulesContainerEdit = reactive({
        name: [{ required: true, message: this.$t('label.required') }],
        ipaddress: [{ required: true, message: this.$t('label.required') }],
        replikaEditValue: [{ required: true, message: this.$t('label.required') }],
        cpuNumberEditValue: [{ required: true, message: this.$t('label.required') }],
        memoryEditValue: [{ required: true, message: this.$t('label.required') }],
        diskEditValue: [{ required: true, message: this.$t('label.required') }],
        licenses: [{ required: true, message: this.$t('label.required') }]
      })
    },
    async fetchData () {
      await this.fetchDeployment()
      await this.getK8sTemplateImages()
      await this.getK8sTemplateList()
      await this.listFreeIps(this.deployment.networkid)
    },
    async fetchDeployment () {
      this.formContainerEdit.ipaddress = this.deployment.ipaddress
      this.formContainerEdit.name = this.deployment.name
      this.formContainerEdit.cpuNumberEditValue = parseInt(this.deployment.cpuLimit)
      this.formContainerEdit.replikaEditValue = parseInt(this.deployment.replicacount)
      this.formContainerEdit.memoryEditValue = parseInt(this.deployment.memLimit)
      this.formContainerEdit.diskEditValue = parseInt(this.deployment.disksize)
      this.formContainerEdit.licenses = parseInt((this.deployment.licenses[0] !== undefined ? this.deployment.licenses[0].count : 1))
    },
    selectContainerTemplate (value) {
      var element = document.getElementById(value)
      element.classList.add('checked')
      this.formContainerEdit.containerTemplate = value
      if (this.checkedRadio) {
        var element2 = document.getElementById(this.checkedRadio)
        element2.classList.remove('checked')
      }
      element.classList.add('checked')
      this.checkedRadio = value
    },
    async listFreeIps (networkId) {
      var params = new FormData()
      params.append('networkuuid', networkId)
      await api2.listFreeIps(params).then(response => {
        this.freeDeploymentIps = response.data.available
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    closeAction () {
      this.$emit('close-action')
    },
    updateComputeReplikaEdit (value) {
      if (value === '' || value < this.minReplika) {
        this.formContainerEdit.replikaEditValue = this.minReplika
      } else if (value > this.maxReplika) {
        this.formContainerEdit.replikaEditValue = this.maxReplika
      }
    },
    updateComputeCpuNumberEdit (value) {
      if (value === '' || value < this.minCpu) {
        this.formContainerEdit.cpuNumberEditValue = this.minCpu
      } else if (value > this.maxCpu) {
        this.formContainerEdit.cpuNumberEditValue = this.maxCpu
      }
    },
    updateComputeMemoryEdit (value) {
      if (value === '' || value < (this.deployment.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory)) {
        this.formContainerEdit.memoryEditValue = this.deployment.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory
      } else if (value > this.maxMemory) {
        this.formContainerEdit.memoryEditValue = this.maxMemory
      }
    },
    updateComputeDiskEdit (value) {
      if (value === '' || value < this.minDisk) {
        this.formContainerEdit.diskEditValue = this.minDisk
      } else if (value > this.maxDisk) {
        this.formContainerEdit.diskEditValue = this.maxDisk
      }
    },
    updateComputeLicenses (value) {
      if (value === '' || value < this.minLicenses) {
        this.formContainerEdit.licenses = this.minLicenses
      } else if (value > this.maxLicenses) {
        this.formContainerEdit.licenses = this.maxLicenses
      }
    },
    setLoading (bool) {
      this.$emit('set-loading', bool)
    },
    async getK8sTemplateImages () {
      this.loading = true
      await api2.getK8sTemplateImages().then(response => {
        this.templateImageInfo = response.data
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    async getK8sTemplateList () {
      this.loading = true
      var params = new FormData()
      params.append('accountuuid', this.accountUuid)
      await api2.getK8sTemplateList(params).then(response => {
        this.k8sTemplates = response.data
        if (this.k8sTemplates.private === null || this.k8sTemplates.private === undefined) {
          this.k8sTemplates.private = []
        } else {
          var privateFileterTemplates = this.k8sTemplates.private.filter(template => template.group === this.deployment.group)
          if (privateFileterTemplates) {
            this.k8sTemplates.private = privateFileterTemplates
          } else {
            this.k8sTemplates.private = []
          }
        }
        if (this.k8sTemplates.public === null || this.k8sTemplates.public === undefined) {
          this.k8sTemplates.public = []
        } else {
          var publicFilterTemplates = this.k8sTemplates.public.filter(template => template.group === this.deployment.group)
          if (publicFilterTemplates) {
            this.k8sTemplates.public = publicFilterTemplates
          } else {
            this.k8sTemplates.private = []
          }
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$notifyError(error)
      })
    },
    handleEditContainerSubmit () {
      this.formRefContainerEdit.value.validate().then(async () => {
        this.loading = true
        this.setLoading(true)
        const values = toRaw(this.formContainerEdit)
        var editContainerParams = new FormData()
        editContainerParams.append('accountId', this.accountUuid)
        if (values.containerTemplate) {
          editContainerParams.append('deploymentPath', values.containerTemplate)
        }
        editContainerParams.append('deploymentId', this.deployment.deploymentid)
        editContainerParams.append('name', values.name)
        editContainerParams.append('replicaCount', values.replikaEditValue)
        editContainerParams.append('cpuLimit', values.cpuNumberEditValue)
        editContainerParams.append('memLimit', values.memoryEditValue)
        editContainerParams.append('diskSize', values.diskEditValue)
        editContainerParams.append('networkId', this.deployment.networkid)
        editContainerParams.append('ipAddress', values.ipaddress)
        editContainerParams.append('gateway', this.gateway)
        editContainerParams.append('count', this.deployment.licenses[0] !== undefined ? values.licenses : 'null')
        editContainerParams.append('tagId', this.deployment.licenses[0] !== undefined ? this.deployment.licenses[0].licenseId : 'null')
        await api2.updateK8sDeployment(editContainerParams).then(response => {
          this.closeAction()
          this.loading = false
          this.setLoading(false)
          this.$notification.success({
            message: this.$t('message.success.update.container')
          })
          this.$emit('fetch-data')
        }).catch(error => {
          console.log(error)
          this.closeAction()
          this.$notification.error({
            message: this.$t('message.error.update.container')
          })
          this.setLoading(false)
          this.$emit('fetch-data')
        })
      })
    }
  }
}
</script >
<style scoped>
  :deep(.ant-card-head) {
    background: white;
  }
  .containerCard {
    width: 250px;
    height: 50px;
    background-color: white;
    margin-bottom: -8px;
    padding: 5px;
    user-select: none;
    display: flex;
  }
  .containerCard ant-card-body {
    padding: 0;
  }
  .containerInner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    height: 100%;
  }
  .checked {
    /* border: 3px solid rgba(61, 124, 175, 1); */
    border: 3px solid rgba(0, 0, 0, 0.15);
  }
  .templateList {
    padding-top: 5px;
    background-color: white;
  }
  .templateInfo {
    margin-top: -5px;
  }
  :deep(.ant-card-body) {
    padding: 0px;
  }
</style>
