<template>
  <a-spin :spinning="loading">
    <a-modal
        :visible="showModal"
        :title="$t('label.container.deployment.create')"
        :maskClosable="false"
        :confirmLoading="loading"
        :width="560"
        @ok="handleAddContainerSubmit"
        @cancel="closeAction"
      >
      <a-form
          v-ctrl-enter="handleAddContainerSubmit"
          :ref="formRefContainer"
          :model="formContainer"
          :rules="rulesContainer"
          @finish="handleAddContainerSubmit"
          layout="vertical"
      >
        <a-form-item ref="account" name="account" :label="$t('label.account')" v-if="fromContainerList">
          <a-select
            v-model:value="formContainer.account"
            @change="selectAccount"
            :placeholder="$t('label.select.account')">
            <a-select-option :value="$store.getters.userInfo.accountid">
              {{ $store.getters.userInfo.account }}
            </a-select-option>
            <a-select-option v-for="subAccounts in resellerSubAccounts" :key="subAccounts.uuid" :value="subAccounts.uuid">
              {{ subAccounts.accountName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="network" name="network" :label="$t('label.network')" v-if="fromContainerList">
          <a-select
            v-model:value="formContainer.network"
            @change="selectNetwork"
            :placeholder="$t('label.select.network')">
            <a-select-option v-for="network in createDeployNetworks" :key="network.id" :value="network.id">
              {{ network.displaytext }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="ipaddressl2" name="ipaddressl2" :label="$t('label.ipaddress')" v-if="isL2Network">
          <a-input
            v-model:value="formContainer.ipaddressl2"
            :placeholder="$t('label.create.ipaddress.description') + '. ' + ' Format: 127.0.0.1/24'">
          </a-input>
        </a-form-item>
        <a-form-item ref="ipaddress" name="ipaddress" :label="$t('label.ipaddress')" v-else>
          <a-select
            v-model:value="formContainer.ipaddress"
            :placeholder="$t('label.create.ipaddress.description') + '. ' + $t('label.select.network.first')">
            <a-select-option v-for="item in freeDeploymentIps" :key="item.address" :value="item.address">
              {{ item.address }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="name" name="name" :label="$t('label.name')">
        <a-input
            :placeholder="$t('label.name.container')"
            v-model:value="formContainer.name">
        </a-input>
        </a-form-item>
        <a-form-item ref="containerTemplate" name="containerTemplate" :label="$t('label.container.template')">
          <a-card :tab-list="tabList" :active-tab-key="key" @tabChange="key => onTabChange(key, 'key')" :bordered="false" :tabSize="'small'">
            <a-list class="templateList" :grid="{ gutter: 10, column: 2 }" :data-source="k8sTemplates[key]" :pagination="pagination">
            <template #renderItem="{item}">
              <a-list-item>
                <a-card
                  class="containerCard"
                  @click="selectContainerTemplate(key + '/' + item.name)"
                  :key="item.name"
                  hoverable
                  :value="key + '/' + item.name"
                  v-model:value="formContainer.containerTemplate">
                  <template #cover>
                    <div :id="key + '/' + item.name" class="containerInner">
                      <img v-if="templateImageInfo.filter(x => x.file_name === item.logo)[0] === undefined" src="../../assets/icons/docker-logo-black.png" style="height: 40px; width: 50px; margin-right: 5px;"/>
                      <img v-else :src="'data:image/png;base64, ' + templateImageInfo.filter(x => x.file_name === item.logo)[0].Content" style="height: 40px; width: 40px; margin-right: 5px;"/>
                      <div v-if="item.name.length>=26">
                        <a-tooltip :title=item.name>{{ item.name.substring(0,25) + '...' }}</a-tooltip>
                      </div>
                      <div v-else>{{ item.name }}</div>
                    </div>
                  </template>
                </a-card>
              </a-list-item>
            </template>
            <div class="templateInfo" v-if="formContainer.containerTemplate !== undefined && k8sTemplates[key].filter(x => this.formContainer.containerTemplate.includes(x.name))[0] !== undefined">
              <span>
                {{ k8sTemplates[key].filter(x => this.formContainer.containerTemplate.includes(x.name))[0].info }}
              </span>
              <br/>
              Ports:
              <span v-for="ports in k8sTemplates[key].filter(x => this.formContainer.containerTemplate.includes(x.name))[0].ports" :key="ports.port">
                {{ ports.port + ' ' }}
              </span>
            </div>
          </a-list>
        </a-card>
        </a-form-item>
        <a-form-item v-if="formContainer.containerTemplate !== undefined && k8sTemplates[key].filter(x => this.formContainer.containerTemplate.includes(x.name))[0].licenses !== null" ref="licenses" name="licenses" :label="$t('label.licences') + (k8sTemplates[key].filter(x => this.formContainer.containerTemplate.includes(x.name))[0].licenses == null ? '' : (' (' + k8sTemplates[key].filter(x => this.formContainer.containerTemplate.includes(x.name))[0].licenses[0].licenseName) + ')')">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <a-slider
            style="width:80%"
            :min="this.minLicenses"
            :max="this.maxLicenses"
            v-model:value="formContainer.licenses"
            @change="($event) => updateComputeLicenses($event)"/>
              <a-input-number
              style="width:15%;"
              v-model:value="formContainer.licenses"
              @change="($event) => updateComputeLicenses($event)"/>
          </div>
        </a-form-item>
        <a-form-item ref="replikaInputValue" name="replikaInputValue" :label="$t('label.replika')">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <a-slider
            style="width:80%"
            :min="this.minReplika"
            :max="this.maxReplika"
            v-model:value="formContainer.replikaInputValue"
            @change="($event) => updateComputeReplika($event)"/>
              <a-input-number
              style="width:15%;"
              v-model:value="formContainer.replikaInputValue"
              @change="($event) => updateComputeReplika($event)"/>
          </div>
        </a-form-item>
        <a-form-item ref="cpuNumberInputValue" name="cpuNumberInputValue" :label="$t('label.cpunumber')">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <a-slider
            style="width:80%"
            :min="this.minCpu"
            :max="this.maxCpu"
            v-model:value="formContainer.cpuNumberInputValue"
            @change="($event) => updateComputeCpuNumber($event)"
            />
            <a-input-number
            style="width:15%;"
            v-model:value="formContainer.cpuNumberInputValue"
            @change="($event) => updateComputeCpuNumber($event)"
            />
          </div>
        </a-form-item>
        <a-form-item ref="memoryInputValue" name="memoryInputValue" :label="$t('label.memory.gb')">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <a-slider
            style="width:80%"
            :min="formContainer.containerTemplate === 'public/mssql:2022-latest' ? 2 : this.minMemory"
            :max="this.maxMemory"
            v-model:value="formContainer.memoryInputValue"
            @change="($event) => updateComputeMemory($event)"
            />
            <a-input-number
            style="width:15%;"
            v-model:value="formContainer.memoryInputValue"
            @change="($event) => updateComputeMemory($event)"
            />
          </div>
        </a-form-item>
        <a-form-item ref="diskInputValue" name="diskInputValue" :label="$t('label.disk.gb')">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <a-slider
            style="width:80%"
            :min="this.minDisk"
            :max="this.maxDisk"
            v-model:value="formContainer.diskInputValue"
            @change="($event) => updateComputeDisk($event)"
            />
            <a-input-number
            style="width:15%;"
            v-model:value="formContainer.diskInputValue"
            @change="($event) => updateComputeDisk($event)"
            />
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-spin>
</template>

<script>

import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { reactive, ref, toRaw } from 'vue'
export default {
  name: 'CreateContainerDeployment',
  components: {
  },
  setup () {
    const pagination = {
      onChange: (page) => {
        formContainer.value.containerTemplate = undefined
        checkedRadio.value = undefined
      },
      pageSize: 8,
      size: 'small'
    }
    const tabList = [
      {
        key: 'public',
        tab: 'Public Templates'
      },
      {
        key: 'private',
        tab: 'Private Templates'
      }
    ]
    const onTabChange = (value, type) => {
      formContainer.value.containerTemplate = undefined
      checkedRadio.value = undefined
      if (type === 'key') {
        key.value = value
      }
    }
    var checkedRadio = ref(undefined)
    const key = ref('public')
    const formContainer = ref({})
    return {
      pagination,
      onTabChange,
      tabList,
      key,
      checkedRadio,
      formContainer
    }
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    },
    fromContainerList: {
      type: Boolean,
      required: true,
      default: false
    },
    resellerSubAccounts: {
      type: Array,
      required: false,
      default: () => ([])
    },
    createContainerNetwork: {
      type: Object,
      required: false,
      default: () => ({})
    },
    gateway: {
      type: String,
      required: false
    },
    accountInfo: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      createDeployNetworks: [],
      showEditContainerModal: false,
      freeDeploymentIps: [],
      k8sTemplates: [],
      selectedNetworkGateway: undefined,
      loading: false,
      pageSize: 20,
      items: [],
      ipRegex: /^(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\.(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\.(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\.(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\/(1?[6-9]|[2][0-9]|3[0-2])$/,
      minReplika: 1,
      maxReplika: 5,
      minCpu: 1,
      maxCpu: 20,
      minMemory: 1,
      maxMemory: 64,
      minDisk: 1,
      maxDisk: 1000,
      minLicenses: 1,
      maxLicenses: 200,
      templateImageInfo: []
    }
  },
  computed: {
    isL2Network () {
      if (this.createDeployNetworks.length > 0) {
        if (this.formContainer.network !== undefined) {
          return this.createDeployNetworks.filter(network => network.id === this.formContainer.network)[0].type === 'L2'
        }
      }
      return false
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    selectContainerTemplate (value) {
      this.formContainer.memoryInputValue = this.minMemory
      var element = document.getElementById(value)
      element.classList.add('checked')
      this.formContainer.containerTemplate = value
      if (this.checkedRadio) {
        var element2 = document.getElementById(this.checkedRadio)
        element2.classList.remove('checked')
      }
      element.classList.add('checked')
      this.checkedRadio = value
    },
    initForm () {
      this.formRefContainer = ref()
      this.formContainer = reactive({
        account: undefined,
        network: undefined,
        name: undefined,
        containerTemplate: undefined,
        ipaddress: undefined,
        ipaddressl2: undefined,
        licenses: 1,
        replikaInputValue: 1,
        cpuNumberInputValue: 1,
        memoryInputValue: 1,
        diskInputValue: 1
      })
      this.rulesContainer = reactive({
        name: [{ required: true, message: this.$t('label.required') }],
        account: [{ required: true, message: this.$t('label.required') }],
        network: [{ required: true, message: this.$t('label.required') }],
        containerTemplate: [{ required: true, message: this.$t('label.required') }],
        ipaddress: [{ required: true, message: this.$t('label.required') }],
        ipaddressl2: [{ required: true, message: this.$t('label.required') }, { validator: this.validateIpAdress }]
      })
    },
    async fetchData () {
      await this.getK8sTemplateImages()
      await this.getK8sTemplateList(this.accountInfo.accUuid)
      if (this.fromContainerList) {
        this.formContainer.account = this.accountInfo.accUuid || undefined
        await this.selectAccount(this.accountInfo.accUuid)
        await this.selectNetwork(this.createDeployNetworks[0].id)
        if (this.createDeployNetworks[0].type !== 'L2') {
          await this.listFreeIps(this.createDeployNetworks[0].id)
        }
      } else {
        if (this.createContainerNetwork.type !== 'L2') {
          await this.listFreeIps(this.createContainerNetwork.id)
        }
      }
    },
    async getK8sTemplateImages () {
      this.loading = true
      await api2.getK8sTemplateImages().then(response => {
        this.templateImageInfo = response.data
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    async getK8sTemplateList (accountUuid) {
      this.loading = true
      var params = new FormData()
      params.append('accountuuid', accountUuid)
      await api2.getK8sTemplateList(params).then(response => {
        this.k8sTemplates = response.data
        if (this.k8sTemplates.private === null) {
          this.k8sTemplates.private = []
        }
        if (this.k8sTemplates.public === null) {
          this.k8sTemplates.public = []
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$notifyError(error)
      })
    },
    async listFreeIps (networkId) {
      this.loading = true
      var params = new FormData()
      params.append('networkuuid', networkId)
      await api2.listFreeIps(params).then(response => {
        this.freeDeploymentIps = response.data.available
        this.loading = false
        this.formContainer.ipaddress = this.freeDeploymentIps[0].address
        this.formContainer.ipaddressl2 = undefined
      }).catch(error => {
        this.$notifyError(error)
        this.loading = false
      })
    },
    validateIpAdress (rule, value) {
      const errorMessage = this.$t('message.error.ipv4.address') + ' Format: 127.0.0.1/24'
      if (this.ipRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async fetchNetworks (accName, domainUuid) {
      await api('listNetworks', { account: accName, domainid: domainUuid, listall: true }).then(json => {
        for (let i = 0; i < json.listnetworksresponse.network.length; i++) {
          if (json.listnetworksresponse.network[i].id !== 'b9c2ab27-b47b-4be7-bf60-bc0452d906b5' && json.listnetworksresponse.network[i].id !== '457765ac-ce51-40d0-bb40-903e077b9a3e') { // excludes IPv4 (1) and VeeamBackupReplication
            this.createDeployNetworks.push(json.listnetworksresponse.network[i])
          }
        }
        if (!json.listnetworksresponse.network || json.listnetworksresponse.network.length === 0) {
          this.createDeployNetworks = []
        }
      }).catch(error => {
        console.log('Error', error)
        this.$notification.error({
          message: this.$t('message.error.update.container')
        })
      })
    },
    async selectAccount (value) {
      this.formContainer.containerTemplate = undefined
      this.formContainer.network = undefined
      this.formContainer.ipaddress = undefined
      this.freeDeploymentIps = []
      await this.getK8sTemplateList(value)
      if (value === this.accountInfo.accUuid) {
        await this.fetchNetworks(this.accountInfo.accName, this.$store.getters.userInfo.domainid)
      } else {
        const selectedSubAccount = this.resellerSubAccounts.filter(subAccount => subAccount.uuid === value)[0]
        await this.fetchNetworks(selectedSubAccount.accountName, selectedSubAccount.domainUuid)
      }
    },
    async selectNetwork (value) {
      this.formContainer.network = value || undefined
      const selectedNetwork = this.createDeployNetworks.filter(network => network.id === value)[0]
      this.selectedNetworkGateway = selectedNetwork.gateway
      if (selectedNetwork.type === 'L2') {
        this.formContainer.ipaddressl2 = undefined
        this.formContainer.ipaddress = undefined
      } else {
        await this.listFreeIps(value)
      }
    },
    updateComputeReplika (value) {
      if (value === '' || value < this.minReplika) {
        this.formContainer.replikaInputValue = this.minReplika
      } else if (value > this.maxReplika) {
        this.formContainer.replikaInputValue = this.maxReplika
      }
    },
    updateComputeLicenses (value) {
      if (value === '' || value < this.minLicenses) {
        this.formContainer.licenses = this.minLicenses
      } else if (value > this.maxLicenses) {
        this.formContainer.licenses = this.maxLicenses
      }
    },
    updateComputeCpuNumber (value) {
      if (value === '' || value < this.minCpu) {
        this.formContainer.cpuNumberInputValue = this.minCpu
      } else if (value > this.maxCpu) {
        this.formContainer.cpuNumberInputValue = this.maxCpu
      }
    },
    updateComputeMemory (value) {
      if (value === '' || value < (this.formContainer.containerTemplate === 'public/mssql:2022-latest' ? 2 : this.minMemory)) {
        this.formContainer.memoryInputValue = this.formContainer.containerTemplate === 'public/mssql:2022-latest' ? 2 : this.minMemory
      } else if (value > this.maxMemory) {
        this.formContainer.memoryInputValue = this.maxMemory
      }
    },
    updateComputeDisk (value) {
      if (value === '' || value < this.minDisk) {
        this.formContainer.diskInputValue = this.minDisk
      } else if (value > this.maxDisk) {
        this.formContainer.diskInputValue = this.maxDisk
      }
    },
    async closeAction () {
      await this.$emit('close-action')
      if (this.$route.path.startsWith('/vpc/')) {
        await this.$emit('force-reload')
      }
      await this.$emit('fetch-data')
    },
    setLoading (bool) {
      this.$emit('set-loading', bool)
    },
    handleAddContainerSubmit () {
      this.formRefContainer.value.validate().then(async () => {
        this.loading = true
        // this.setLoading(true)
        const values = toRaw(this.formContainer)
        var createContainerParams = new FormData()
        createContainerParams.append('accountId', this.fromContainerList ? values.account : this.accountInfo.accUuid)
        createContainerParams.append('deploymentPath', values.containerTemplate)
        createContainerParams.append('name', values.name)
        createContainerParams.append('replicaCount', values.replikaInputValue)
        createContainerParams.append('diskSize', values.diskInputValue)
        createContainerParams.append('networkId', this.fromContainerList ? values.network : this.createContainerNetwork.id)
        createContainerParams.append('ipAddress', values.ipaddress || values.ipaddressl2)
        createContainerParams.append('cpuLimit', values.cpuNumberInputValue)
        createContainerParams.append('memLimit', values.memoryInputValue)
        createContainerParams.append('count', values.licenses)
        createContainerParams.append('gateway', this.fromContainerList ? this.selectedNetworkGateway : this.gateway)
        await api2.createK8sDeployment(createContainerParams).then(response => {
          this.loading = false
          this.setLoading(false)
          this.closeAction()
          this.$notification.success({
            message: this.$t('message.success.add.container')
          })
        }).catch(error => {
          console.log(error)
          this.setLoading(false)
          this.closeAction()
          this.$notification.error({
            message: this.$t('message.error.add.container')
          })
          this.loading = false
        })
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
  :deep(.ant-card-head) {
    background: white;
  }
.containerCard {
  width: 250px;
  height: 50px;
  background-color: white;
  margin-bottom: -8px;
  padding: 5px;
  user-select: none;
  display: flex;
}
.containerCard ant-card-body {
  padding: 0;
}
.containerInner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  height: 100%;
}
.checked {
  /* border: 3px solid rgba(61, 124, 175, 1); */
  border: 3px solid rgba(0, 0, 0, 0.15);
}
.templateList {
  padding-top: 5px;
  background-color: white;
}
.templateInfo {
  margin-top: -5px;
}
:deep(.ant-card-body) {
  padding: 0px;
}
</style>
