<template>
  <a-spin :spinning="loading">
    <!-- Show activate/deactivate PBS Modal -->
    <a-modal
      style="height: 50em;"
      v-model:visible="showPbsModal"
      :title="isActivated ? $t('label.pbs.on.title') : $t('label.pbs.off.title')"
      :closable="true"
      @cancel="closePbsModal"
      @ok="submitPbsModal"
      centered>
      <render-icon icon="question-circle-outlined" :style="{fontSize: '24px', color: 'orange'}"></render-icon>
      <p v-if="isActivated">{{ $t('label.pbs.on.content') }}</p>
      <p v-else>{{ $t('label.pbs.off.content') }}</p>
        <!-- <a-form-item
          :label="'Enter Token'">
          <a-input
            autoFocus
            autocomplete="false"
            placeholder="Token"
            v-decorator="['token', {
              rules: [{
                required: true,
                message: 'Bitte geben Sie ihr PBS VM Token an!'
              }, { validator: validatePbsToken }]
            }]"/>
        </a-form-item> -->
    </a-modal>
    <!-- Confirmation Modal -->
    <a-modal
      style="top: 20px;"
      centered
      :title="$t('label.succeeded') + '!'"
      :closable="false"
      :maskClosable="false"
      v-model:visible="confirmationModal"
    >
      <render-icon icon="queston-circle-outlined" :style="{fontSize: '24px', color: 'orange'}"></render-icon>
      <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
      <template #footer style="text-align: right;">
        <a-button id="confirmationOkayButton" type="primary" @click="confirmationModal=false">OK</a-button>
      </template>
    </a-modal>
    <div class='card-grey'>
      <div class='card-grey-header'>
        <cloud-server-outlined class='is--blue' /> {{ 'Cyber Cloud' }}
      </div>
      <div class="activatePbsContainer">
        <span>{{ isActivated ? $t('label.deactivate') : $t('label.activate') }}</span>
        <div>
          <a-switch id="activatePbs" v-model:checked="isActivated" @click="showPbsModal = true"/>
          <img class="shieldIcon" v-if="isActivated" src="../../assets/icons/FirewallShieldDefaultAllow.png"/>
          <img class="shieldIcon" v-else src="../../assets/icons/FirewallShieldDefaultDeny.png"/>
        </div>
      </div>
      <render-icon style="margin-right: 10px; font-size: 15px" icon="play-circle-outlined"/>
      <a
        :href="'https://www.youtube.com/watch?v=l6703P3Dbfk'"
        target="_blank">
        <span>Link zum Installationsvideo</span>
      </a>
      <a-divider/>
      <div class="title" v-if="isActivated">{{ $t('label.advanced.pbs.active') }}</div>
      <p v-if="isActivated">
        {{ $t('message.advanced.pbs') }}
      </p>
      <div v-if="isActivated">
        <div v-if="onetimeUrl !== undefined">
          <render-icon style="margin-right: 10px; font-size: 15px" icon="link-outlined"/>
          <a
            :href="onetimeUrl"
            target="_blank">
            <span>Acronis Login</span>
          </a>
        </div>
        <render-icon style="margin-right: 10px; font-size: 15px" icon="play-circle-outlined"/>
        <a
          :href="'https://www.youtube.com/watch?v=-rkSqtoI1e0'"
          target="_blank">
          <span>Link zum Tutorialvideo</span>
        </a>
      </div>
    </div>
  </a-spin>
</template>

<script>
import api2 from '@/wpApi/api2'
import { api } from '@/api'

export default {
  name: 'AdvancedPbsTab',
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isActivated: false,
      onetimeUrl: undefined,
      vmOwnerAccUuid: String,
      loading: false,
      confirmationModal: false,
      confirmationMessage: String,
      showPbsModal: false,
      regex: /^[A-Z0-9]{4}-[A-Z0-9]{4}$/,
      acronisTenantIdCheck: /^[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/
    }
  },
  /* beforeCreate () {
    this.form = this.$form.createForm(this)
  }, */
  watch: {
    resource: {
      handler (newData, oldData) {
        if (newData !== oldData) {
          this.fetchAcronisVmStatus()
        }
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.getVmOwnerAccId()
    await this.fetchAcronisVmStatus()
    this.generateOneTimeUrl()
    this.loading = false
  },
  methods: {
    linkClicked () {
      setTimeout(function () { location.reload() }, 200)
    },
    validatePbsToken (rule, value, callback) {
      const errorMessage = this.$t('message.validate.special.characters.pbsToken')
      if (this.regex.test(value)) {
        callback()
        return
      }
      callback(errorMessage)
    },
    openConfirmationModal (msg) {
      this.confirmationMessage = msg
      this.confirmationModal = true
    },
    closePbsModal () {
      this.fetchAcronisVmStatus()
      this.showPbsModal = false
    },
    /**
     * Submit Function for adding a AcronisVM
     *
     * If VM is allready added -> call API2 "deleteAcronisVM" with cloud_account_id (AccontUUID) and vm_id (VmUUID)
     * if VM is not added -> calls API2 "addAcronisVM" with cloud_account_id (AccontUUID) and vm_id (VmUUID)
     */
    async submitPbsModal () {
      if (this.isActivated) {
        this.loading = true
        var addAcronisVmParams = new FormData()
        addAcronisVmParams.append('cloud_account_id', this.vmOwnerAccUuid)
        addAcronisVmParams.append('vm_id', this.resource.id)
        await api2.addAcronisVM(addAcronisVmParams).then(async response => {
          if (response.data.status === 'ok') {
            // fetch updated status in backend to update the activation-slider
            await this.fetchAcronisVmStatus()
            this.showPbsModal = false
            this.loading = false
          } else {
            this.showPbsModal = false
            this.loading = false
          }
        }).catch(error => {
          this.$notifyError(error)
          this.isActivated = false
          this.showPbsModal = false
          this.loading = false
        })
      } else {
        this.loading = true
        var deleteAcronisVmParams = new FormData()
        deleteAcronisVmParams.append('cloud_account_id', this.vmOwnerAccUuid)
        deleteAcronisVmParams.append('vm_id', this.resource.id)
        await api2.deleteAcronisVM(deleteAcronisVmParams).then(response => {
          if (response.data.status === 'ok') {
            this.showPbsModal = false
            this.loading = false
            this.fetchAcronisVmStatus()
          } else {
            this.fetchAcronisVmStatus()
            this.showPbsModal = false
            this.loading = false
          }
        }).catch(error => {
          this.$notifyError(error)
          this.fetchAcronisVmStatus()
          this.showPbsModal = false
          this.loading = false
        })
      }
    },
    /**
     * Get the AccountId of the owner of the current VM
     *
     * call clodustack "listAccounts" Api and compare its accountname with the accountname of the current VM
     */
    async getVmOwnerAccId () {
      await api('listAccounts', { listall: true, name: this.resource.account }).then(response => {
        var responseAccUuid = response.listaccountsresponse.account.filter(account => account.name === this.resource.account)[0].id
        this.vmOwnerAccUuid = responseAccUuid !== undefined ? responseAccUuid : ''
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    /**
     * Get the status of the current VM, if its connected with acronis or not.
     *
     * call API2 "getAcronisVM" with vmid (VmUUID) to fetch the VM Status and save it in the global variable "isActivated"
     */
    async fetchAcronisVmStatus () {
      var getUserParams = new FormData()
      getUserParams.append('vmid', this.resource.id)
      await api2.getAcronisVM(getUserParams).then(response => {
        if (response.data && response.data.status && response.data.status === 'error') {
          this.isActivated = false
          this.loading = false
        } else if (response.data && response.data.id && this.acronisTenantIdCheck.test(response.data.id)) {
          this.isActivated = true
          this.loading = false
        } else {
          console.log('Failed to request Acronis VM Status')
          this.isActivated = false
          this.loading = false
        }
      }).catch(error => {
        this.$notifyError(error)
        this.isActivated = false
        this.loading = false
      })
    },
    /**
     * Create OneTimeUrl to connect to the Acronis Dashboard
     *
     * call API2 "generateOneTimeUrl" with accountid (Account UUID) to get the URL.
     */
    generateOneTimeUrl () {
      var urlParams = new FormData()
      urlParams.append('accountid', this.vmOwnerAccUuid)
      api2.generateOneTimeUrl(urlParams).then(response => {
        if (response.data && response.data.status && response.data.status === 'error') {
          this.onetimeUrl = undefined
        } else if (response.data && response.data.includes('acronis.com')) {
          this.onetimeUrl = response.data
        } else {
          this.onetimeUrl = undefined
        }
      }).catch((error) => {
        this.onetimeUrl = undefined
        console.log(error)
        console.log('No active Cyber Cloud Account!')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  margin-bottom: 5px;
  font-weight: bold;
}

.activatePbsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12em;
  margin-top: 20px;
}

.shieldIcon {
  width:1.5rem;
  height:1.7rem;
  margin-left: 10px;
}
</style>
