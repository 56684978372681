<template>
  <div class="card-grey" style="position: relative; left: 152px; height: 50px; width: calc(100% - 163px); padding-top: 5px; margin: 0 0 6px 0;" v-if="$route.path.startsWith('/vpc/')">
    <div class='instance-details-modal'>
      <div class='modal-instance-header' style="margin-top: 4px;">
        <div class="modal-instance-header-left">
          <div class="simple-vm-state-container">
            <a-tooltip placement="top" v-if="resource.podsready.split('\u0022')[1] === '0' || resource.podsready.split('\u0022')[1]  === '' || resource.podsready.split('\u0022')[1]  === undefined">
              <template #title>
              <span class="simple-vm-state-text">{{ '(' + 0 + '/' + resource.replicacount + 'Replikas)' }}</span>
              </template>
              <div class="simple-vm-state stopped"></div>
            </a-tooltip>
            <a-tooltip placement="top" v-else-if="resource.podsready.split('\u0022')[1] < resource.replicacount">
                <template #title>
                <span class="simple-vm-state-text">{{ '(' + resource.podsready.split('\u0022')[1] + '/' + resource.replicacount + 'Replikas)' }}</span>
                </template>
                <div class="simple-vm-state stopped"></div>
            </a-tooltip>
            <a-tooltip placement="top" v-else>
                <template #title>
                <span class="simple-vm-state-text">{{  'Running (' + resource.podsready.split('\u0022')[1] + '/' + resource.replicacount + 'Replikas)'  }}</span>
                </template>
                <div class="simple-vm-state running" ></div>
            </a-tooltip>
          </div>
          <div class='modal-instance-header-name' >{{ resource.name }}</div>
        </div>
        <div class="modal-instance-header-right">
          <DeploymentActionButton :resource="resource" :vpcOwnerAccUuid="vpcOwnerAccUuid" :vpcResource="vpcResource" :k8sTemplateImages="k8sTemplateImages"/>
          <div class="closeVpcVmElement" @click="closeVmInfoModal"><CloseOutlined :style="{fontSize: '25px', color: 'grey'}"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { api } from '@/api'
// import api2 from '@/wpApi/api2'
import eventBus from '@/config/eventBus'
import DeleteContainerDeployment from './DeleteContainerDeployment.vue'
import EditContainerDeployment from './EditContainerDeployment.vue'
import DeploymentActionButton from './DeploymentActionButton.vue'

export default {
  name: 'DeploymentMenuHeader',
  components: {
    api,
    DeleteContainerDeployment,
    EditContainerDeployment,
    DeploymentActionButton
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    vpcOwnerAccUuid: {
      type: String,
      required: true
    },
    vpcResource: {
      type: Object,
      required: true
    },
    k8sTemplateImages: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDeleteContainerModal: false,
      showEditContainerModal: false
    }
  },
  methods: {
    fetchData () {
      eventBus.emit('vpc-fetch-data')
    },
    execAction (action, isGroupAction) {
      eventBus.emit('exec-action', { action, isGroupAction })
    },
    closeAction () {
      this.showDeleteContainerModal = false
      this.showEditContainerModal = false
    },
    closeVmInfoModal () {
      eventBus.emit('close-vmDetails', { destroyBool: false, vmUuid: this.resource.id })
    }
  }
}
</script>
<style lang="scss">
  .closeVpcVmElement {
    font-weight: bold;
    margin-left: 20px;
  }
  .closeVpcVmElement:hover {
    cursor: pointer;
  }
</style>
