// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div>
    <a-affix :offsetTop="78">
      <a-card class="breadcrumb-card" style="z-index: 10">
        <a-row>
          <a-col :span="device === 'mobile' ? 24 : 12" style="padding-left: 12px">
            <breadcrumb :resource="resource">
              <template #end>
                <a-button
                  :loading="loading"
                  style="margin-bottom: 5px"
                  shape="round"
                  size="small"
                  @click="fetchData({ irefresh: true })">
                  <template #icon><reload-outlined /></template>
                  {{ $t('label.refresh') }}
                </a-button>
                <!-- WP Implementation -->
                <a-switch
                  v-if="!dataView && ['volume', 'zone', 'cluster', 'host', 'storagepool', 'managementserver'].includes($route.name)"
                  style="margin-left: 8px"
                  :checked-children="$t('label.metrics')"
                  :un-checked-children="$t('label.metrics')"
                  :checked="$store.getters.metrics"
                  @change="(checked, event) => { $store.dispatch('SetMetrics', checked) }"/>
                <!-- <a-switch
                  v-if="!projectView && hasProjectId"
                  style="margin-left: 8px"
                  :checked-children="$t('label.projects')"
                  :un-checked-children="$t('label.projects')"
                  :checked="$store.getters.listAllProjects"
                  @change="(checked, event) => { $store.dispatch('SetListAllProjects', checked) }"/> -->
                  <!-- /WP Implementation -->
                <!-- <a-tooltip placement="right">
                  <template #title>
                    {{ $t('label.filterby') }}
                  </template>
                  <a-select
                    v-if="!dataView && filters && filters.length > 0"
                    :placeholder="$t('label.filterby')"
                    :value="$route.query.filter || (projectView && $route.name === 'vm' ||
                      ['Admin', 'DomainAdmin'].includes($store.getters.userInfo.roletype) && ['vm', 'iso', 'template'].includes($route.name)
                      ? 'all' : ['publicip'].includes($route.name)
                        ? 'allocated' : ['guestnetwork', 'guestvlans'].includes($route.name) ? 'all' : 'self')"
                    style="min-width: 120px; margin-left: 10px"
                    @change="changeFilter"
                    showSearch
                    optionFilterProp="label"
                    :filterOption="(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }" >
                    <template #suffixIcon><filter-outlined class="ant-select-suffix" /></template>
                    <a-select-option
                      v-if="['Admin', 'DomainAdmin'].includes($store.getters.userInfo.roletype) && ['vm', 'iso', 'template'].includes($route.name)"
                      key="all"
                      :label="$t('label.all')">
                      {{ $t('label.all') }}
                    </a-select-option>
                    <a-select-option
                      v-for="filter in filters"
                      :key="filter"
                      :label="$t('label.' + (['comment'].includes($route.name) ? 'filter.annotations.' : '') + filter)">
                      {{ $t('label.' + (['comment'].includes($route.name) ? 'filter.annotations.' : '') + filter) }}
                      <clock-circle-outlined v-if="['comment'].includes($route.name) && !['Admin'].includes($store.getters.userInfo.roletype) && filter === 'all'" />
                    </a-select-option>
                  </a-select>
                </a-tooltip> -->
              </template>
            </breadcrumb>
          </a-col>
          <a-col
            :span="device === 'mobile' ? 24 : 11"
            :style="device === 'mobile' ? { float: 'right', 'margin-top': '12px', 'margin-bottom': '-6px', display: 'table' } : { float: 'right', display: 'table', 'margin-bottom': '-6px' }" >
            <slot name="action" v-if="dataView && $route.path.startsWith('/publicip')" style="border: 1px solid red;"></slot>
            <action-button
              v-else
              :style="dataView ? { float: device === 'mobile' ? 'left' : 'right' } : { 'margin-right': '10px', display: getStyle(), padding: '5px' }"
              :loading="loading"
              :actions="actions"
              :selectedRowKeys="selectedRowKeys"
              :selectedItems="selectedItems"
              :dataView="dataView"
              :resource="resource"
              :getVgpuInfoResponse="getVgpuInfoResponse"
              @exec-action="(action) => execAction(action, action.groupAction && !dataView)"/>
            <search-view
              v-if="!dataView"
              :searchFilters="searchFilters"
              :searchParams="searchParams"
              :apiName="apiName"
              @search="onSearch"
              @change-filter="changeFilter"/>
              <a-button v-if="$route.path.startsWith('/publicip')" type="primary" shape="round" @click="autoRequestIps" style="margin-left: 15px; top: 4px;">{{ $t('label.acquire.new.ip') }}</a-button>
          </a-col>
        </a-row>
      </a-card>
    </a-affix>

    <div v-show="showAction">
      <keep-alive v-if="currentAction.component && (!currentAction.groupAction || selectedRowKeys.length === 0 || (this.selectedRowKeys.length > 0 && currentAction.api === 'destroyVirtualMachine'))">
        <a-modal
          :visible="showAction"
          :closable="true"
          :maskClosable="false"
          :cancelText="$t('label.cancel')"
          style="top: 20px;"
          @cancel="closeAction"
          :confirmLoading="actionLoading"
          :footer="null"
          centered
          width="auto"
        >
          <template #title>
            <span v-if="currentAction.label">{{ $t(currentAction.label) }}</span>
            <a
              v-if="currentAction.docHelp || $route.meta.docHelp"
              style="margin-left: 5px"
              :href="$config.docBase + '/' + (currentAction.docHelp || $route.meta.docHelp)"
              target="_blank">
              <question-circle-outlined />
            </a>
          </template>
          <keep-alive>
            <component
              :is="currentAction.component"
              :resource="resource"
              :loading="loading"
              :action="{currentAction}"
              :selectedRowKeys="selectedRowKeys"
              :selectedItems="selectedItems"
              :chosenColumns="chosenColumns"
              v-bind="{currentAction}"
              @refresh-data="fetchData"
              @poll-action="pollActionCompletion"
              @close-action="closeAction"
              @cancel-bulk-action="handleCancel"/>
          </keep-alive>
        </a-modal>
      </keep-alive>
      <a-modal
        v-else
        :visible="showAction"
        :closable="true"
        :maskClosable="false"
        :footer="null"
        style="top: 20px;"
        :width="modalWidth"
        :ok-button-props="getOkProps()"
        :cancel-button-props="getCancelProps()"
        :confirmLoading="actionLoading"
        @cancel="closeAction"
        centered
      >
        <template #title>
          <span v-if="currentAction.label">{{ $t(currentAction.label) }}</span>
          <a
            v-if="currentAction.docHelp || $route.meta.docHelp"
            style="margin-left: 5px"
            :href="$config.docBase + '/' + (currentAction.docHelp || $route.meta.docHelp)"
            target="_blank">
            <question-circle-outlined />
          </a>
        </template>
        <a-spin :spinning="actionLoading" v-ctrl-enter="handleSubmit">
          <span v-if="currentAction.message">
            <div v-if="selectedRowKeys.length > 0">
              <a-alert
                v-if="['delete-outlined', 'DeleteOutlined', 'poweroff-outlined', 'PoweroffOutlined'].includes(currentAction.icon)"
                type="error">
                <template #message>
                  <exclamation-circle-outlined style="color: red; fontSize: 30px; display: inline-flex" />
                  <span style="padding-left: 5px" v-html="`<b>${selectedRowKeys.length} ` + $t('label.items.selected') + `. </b>`" />
                  <span v-html="$t(currentAction.message)" />
                </template>
              </a-alert>
              <a-alert v-else type="warning">
                <template #message>
                  <span v-if="selectedRowKeys.length > 0" v-html="`<b>${selectedRowKeys.length} ` + $t('label.items.selected') + `. </b>`" />
                  <span v-html="$t(currentAction.message)" />
                </template>
              </a-alert>
            </div>
            <div v-else>
              <a-alert type="warning">
                <template #message>
                  <span v-html="$t(currentAction.message)" />
                </template>
              </a-alert>
            </div>
            <div v-if="selectedRowKeys.length > 0">
              <a-divider />
              <a-table
                v-if="selectedRowKeys.length > 0"
                size="middle"
                :columns="chosenColumns"
                :dataSource="selectedItems"
                :rowKey="(record, idx) => record.id || record.name || record.usageType || idx + '-' + Math.random()"
                :pagination="true"
                style="overflow-y: auto"
              >
              </a-table>
            </div>
            <br v-if="currentAction.paramFields.length > 0"/>
          </span>
          <a-form
            :ref="formRef"
            :model="form"
            :rules="rules"
            @finish="handleSubmit"
            layout="vertical">
            <a-form-item v-if="currentAction.api === 'createTemplate' && $store.getters.userInfo.roletype === 'Admin'">
              <template #label>
                {{ $t('label.licences') }}
                <a-tooltip :title="'Verknüpfe Lizenztags mit dem neuen Template'">
                  <render-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                </a-tooltip>
              </template>
              <a-switch
                style="margin-bottom: 10px;"
                v-model:checked="licences">
              </a-switch>
              <!-- data-source will be the list of tags from tag DB -->
              <div v-if="licences">
                <Transfer
                  :data-source="transferInput"
                  :list-style="{
                    width: '46.3%',
                    height: '250px',
                  }"
                  :titles="['Lizenztypen', 'Ausgewählt']"
                  :target-keys="targetTransferKeys"
                  :selected-keys="selectedKeys"
                  :render="renderItem"
                  @selectChange="handleSelectChange"
                  @change="handleTransferChange"
                />
                <!-- create a new tag that leads to a new entry in the quota_usage_tariff DB -->
                <a-button
                  type="dashed"
                  size="small"
                  style="float:left;margin-top: 5px"
                  @click="setCreateTagTrue"
                  :disabled="$store.getters.userInfo.roletype !== 'Admin'">
                  <template #icon>
                    <PlusOutlined/>
                  </template>
                  {{ 'Neuen Lizenz-Typ hinzufügen' }}
                </a-button>
                <div v-if="showCreateNewTagInput">
                  <a-input
                    style="margin-top: 5px;"
                    v-model:value="tagName"
                    :placeholder="'Tag Name'" />
                  <a-input-number
                    style="margin-top: 2px;"
                    v-model:value="tagPrice"
                    :placeholder="'Price per Licence'" /> €
                  <a-select
                    style="margin-top: 2px;"
                    v-model:value="tagType"
                    :placeholder="'Tag Type'"
                  >
                    <a-select-option v-for="tagType in getUniques(transferInput, 'type')" :key="tagType">
                      {{ tagType }}
                    </a-select-option>
                  </a-select>
                  <a-select
                    style="margin-top: 2px;"
                    v-model:value="tagColor"
                    :placeholder="'Tag Color'"
                  >
                    <a-select-option v-for="tagColor in getUniques(transferInput, 'color')" :key="tagColor">
                      <a-tag :color="tagColor">{{ tagColor }}</a-tag>
                    </a-select-option>
                  </a-select>
                  <p v-if="inputAllLicenseFields" style="color:red;">Bitte alle Input-Felder zum erstellen eines Lizenztags ausfüllen!</p>
                  <a-button
                    type="primary"
                    size="small"
                    style="float:left;margin-top: 5px"
                    @click="createNewTag()">
                    <template #icon>
                      <PlusOutlined/>
                    </template>
                    {{ 'Create Tag' }}
                  </a-button>
                </div>
              </div>
            </a-form-item>
            <div v-for="(field, fieldIndex) in currentAction.paramFields" :key="fieldIndex">
              <a-form-item
                :name="field.name"
                :ref="field.name"
                :v-bind="field.name"
                v-if="!(currentAction.mapping && field.name in currentAction.mapping && currentAction.mapping[field.name].value)"
              >
                <template #label>
                  <tooltip-label :title="$t('label.' + field.name)" :tooltip="field.description"/>
                </template>

                <a-switch
                  v-if="field.type==='boolean'"
                  v-model:checked="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                />
                <a-select
                  v-else-if="currentAction.mapping && field.name in currentAction.mapping && currentAction.mapping[field.name].options"
                  :loading="field.loading"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                  showSearch
                  optionFilterProp="label"
                  :filterOption="(input, option) => {
                    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                >
                  <a-select-option key="" >{{ }}</a-select-option>
                  <a-select-option v-for="(opt, optIndex) in currentAction.mapping[field.name].options" :key="optIndex">
                    {{ opt }}
                  </a-select-option>
                </a-select>
                <!-- Added an Additional "else if" that includes having "vpnuser" in the url when calling the form. That way the domain ID and Account are only required, when adding a vpnuser.
              (Autogen View creates dynamic forms for different action buttons) -->
              <!-- WP Implementation -->
                <a-select
                  v-else-if="$route.path.startsWith('/vpnuser') && field.name==='account' && !['addAccountToProject', 'createAccount'].includes(currentAction.api)"
                  showSearch
                  optionFilterProp="label"
                  v-model:value="form[field.name]"
                  :loading="field.loading"
                  :placeholder="'account for the vpn user.'"
                  :filterOption="(input, option) => {
                    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                >
                  <a-select-option key="">{{ }}</a-select-option>
                  <a-select-option v-for="(opt, optIndex) in field.opts" :key="optIndex">
                    {{ opt.name || opt.description || opt.traffictype || opt.publicip }}
                  </a-select-option>
                </a-select>
              <!-- /WP Implementation -->
                <a-select
                  v-else-if="field.name==='keypair' ||
                    (field.name==='account' && !['addAccountToProject', 'createAccount'].includes(currentAction.api))"
                  showSearch
                  optionFilterProp="label"
                  v-model:value="form[field.name]"
                  :loading="field.loading"
                  :placeholder="field.description"
                  :filterOption="(input, option) => {
                    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                  v-focus="fieldIndex === firstIndex"
                >
                  <a-select-option key="">{{ }}</a-select-option>
                  <a-select-option v-for="(opt, optIndex) in field.opts" :key="optIndex">
                    {{ opt.name || opt.description || opt.traffictype || opt.publicip }}
                  </a-select-option>
                </a-select>
                <!-- WP Implementation -->
                <a-select
                  v-else-if="field.type==='uuid' && $route.path.startsWith('/vpnuser')"
                  showSearch
                  optionFilterProp="label"
                  v-model:value="form[field.name]"
                  :loading="field.loading"
                  :placeholder="'domainId for the vpn user.'"
                  :filterOption="(input, option) => {
                    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                >
                  <a-select-option key="">{{ }}</a-select-option>
                  <a-select-option v-for="opt in field.opts" :key="opt.id">
                    {{ opt.name || opt.description || opt.traffictype || opt.publicip }}
                  </a-select-option>
                </a-select>
              <!-- /WP Implementation -->
                <a-select
                  v-else-if="field.type==='uuid'"
                  showSearch
                  optionFilterProp="label"
                  v-model:value="form[field.name]"
                  :loading="field.loading"
                  :placeholder="field.description"
                  :filterOption="(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                  v-focus="fieldIndex === firstIndex"
                >
                  <a-select-option key="" label="">{{ }}</a-select-option>
                  <a-select-option v-for="opt in field.opts" :key="opt.id" :label="opt.name || opt.description || opt.traffictype || opt.publicip">
                    <div>
                      <span v-if="(field.name.startsWith('template') || field.name.startsWith('iso'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <os-logo v-else :osId="opt.ostypeid" :osName="opt.ostypename" size="lg" style="margin-left: -1px" />
                      </span>
                      <span v-if="(field.name.startsWith('zone'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <global-outlined v-else style="margin-right: 5px" />
                      </span>
                      <span v-if="(field.name.startsWith('project'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <project-outlined v-else style="margin-right: 5px" />
                      </span>
                      <span v-if="(field.name.startsWith('account') || field.name.startsWith('user'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <user-outlined v-else style="margin-right: 5px"/>
                      </span>
                      <span v-if="(field.name.startsWith('network'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <apartment-outlined v-else style="margin-right: 5px"/>
                      </span>
                      <span v-if="(field.name.startsWith('domain'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <block-outlined v-else style="margin-right: 5px"/>
                      </span>
                      {{ opt.name || opt.description || opt.traffictype || opt.publicip }}
                    </div>
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="field.type==='list'"
                  :loading="field.loading"
                  mode="multiple"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                  showSearch
                  optionFilterProp="label"
                  :filterOption="(input, option) => {
                    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                >
                  <a-select-option v-for="(opt, optIndex) in field.opts" :key="optIndex">
                    {{ opt.name && opt.type ? opt.name + ' (' + opt.type + ')' : opt.name || opt.description }}
                  </a-select-option>
                </a-select>
                <a-input-number
                  v-else-if="field.type==='long'"
                  v-focus="fieldIndex === firstIndex"
                  style="width: 100%;"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                />
                <a-input-password
                  v-else-if="field.name==='password' || field.name==='currentpassword' || field.name==='confirmpassword'"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  @blur="($event) => handleConfirmBlur($event, field.name)"
                  v-focus="fieldIndex === firstIndex"
                />
                <a-textarea
                  v-else-if="field.name==='certificate' || field.name==='privatekey' || field.name==='certchain'"
                  rows="2"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                />
                <a-input
                  v-else-if="field.name === 'newname' && ['updateAccount'].includes(currentAction.api)"
                  v-focus="fieldIndex === firstIndex"
                  v-model:value="form[field.name]"
                  :placeholder="field.description" />
                <a-input
                  v-else
                  v-focus="fieldIndex === firstIndex"
                  v-model:value="form[field.name]"
                  :placeholder="field.description" />
              </a-form-item>
            </div>

            <div :span="24" class="action-button">
              <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
              <a-button type="primary" @click="handleSubmit" ref="submit">{{ $t('label.ok') }}</a-button>
            </div>
          </a-form>
        </a-spin>
        <br />
      </a-modal>
    </div>
    <!-- WP Implementation -->
    <a-modal
      v-model:visible="ipRequested"
      centered
      @cancel="ipRequested=false">
      <render-icon icon="check-outlined" :style="{fontSize: '24px', color: 'green'}"></render-icon>
      <br/>
      <p> {{ $t('message.iprange.request.success') }}</p>
      <template #footer style="text-align: right;">
        <a-button :loading="loading" type="primary" @click="ipRequested=false">{{ this.$t('label.ok') }}</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model:visible="ipRequestedFail"
      centered
      @cancel="ipRequestedFail=false">
      <render-icon icon="close-outlined" :style="{fontSize: '24px', color: 'red'}"></render-icon>
      <br/>
      <p>{{ $t('message.iprange.request.failed') }}</p>
      <template #footer style="text-align: right;">
        <a-button :loading="loading" type="primary" @click="ipRequestedFail=false">{{ this.$t('label.ok') }}</a-button>
      </template>
    </a-modal>
    <!-- WP Implementation -->

    <div v-if="dataView" style="margin-top: -10px">
      <slot name="resource" v-if="$route.path.startsWith('/quotasummary') || $route.path.startsWith('/publicip')"></slot>
      <!-- WP Implementation -->
      <resource-view
        v-else
        :resource="resource"
        :actions="actions"
        :loading="loading"
        :refreshCalled="refreshCalled"
        :tabs="$route.meta.tabs" />
      <!-- /WP Implementation -->
    </div>
    <div class="row-element" v-else>
      <list-view
        :loading="loading"
        :columns="columns"
        :items="items"
        :actions="actions"
        :columnKeys="columnKeys"
        :selectedColumns="selectedColumns"
        ref="listview"
        @update-selected-columns="updateSelectedColumns"
        @selection-change="onRowSelectionChange"
        @refresh="this.fetchData"
        @edit-tariff-action="(showAction, record) => $emit('edit-tariff-action', showAction, record)"/>
      <a-pagination
        class="row-element"
        style="margin-top: 10px"
        size="small"
        :current="page"
        :pageSize="getPageSize(pageSize)"
        :total="itemCount"
        :showTotal="total => `${$t('label.showing')} ${Math.min(total, 1+((page-1)*pageSize))}-${Math.min(page*pageSize, total)} ${$t('label.of')} ${total} ${$t('label.items')}`"
        :pageSizeOptions="pageSizeOptions"
        @change="changePage"
        @showSizeChange="changePageSize"
        showSizeChanger
        showQuickJumper>
        <template #buildOptionText="props">
          <span>{{ props.value }} / {{ $t('label.page') }}</span>
        </template>
      </a-pagination>
    </div>
    <bulk-action-progress
      :showGroupActionModal="showGroupActionModal"
      :selectedItems="selectedItems"
      :selectedColumns="bulkColumns"
      :message="modalInfo"
      @handle-cancel="handleCancel" />
  </div>
</template>

<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
// WP Implementation
import wpapi from '../wpApi/myApi'
import api2 from '@/wpApi/api2'
import { Transfer } from 'ant-design-vue'
// WP Implementation
import { mixinDevice } from '@/utils/mixin.js'
import { genericCompare } from '@/utils/sort.js'
import store from '@/store'
import eventBus from '@/config/eventBus'

import Breadcrumb from '@/components/widgets/Breadcrumb'
import ListView from '@/components/view/ListView'
import ResourceView from '@/components/view/ResourceView'
import ActionButton from '@/components/view/ActionButton'
import SearchView from '@/components/view/SearchView'
import OsLogo from '@/components/widgets/OsLogo'
import ResourceIcon from '@/components/view/ResourceIcon'
import BulkActionProgress from '@/components/view/BulkActionProgress'
import TooltipLabel from '@/components/widgets/TooltipLabel'

export default {
  name: 'Resource',
  components: {
    Breadcrumb,
    ResourceView,
    ListView,
    ActionButton,
    SearchView,
    BulkActionProgress,
    TooltipLabel,
    OsLogo,
    ResourceIcon,
    // WP Implementation
    Transfer
    // /WP Implementation
  },
  mixins: [mixinDevice],
  provide: function () {
    return {
      parentFetchData: this.fetchData,
      parentToggleLoading: this.toggleLoading,
      parentStartLoading: this.startLoading,
      parentFinishLoading: this.finishLoading,
      parentSearch: this.onSearch,
      parentChangeFilter: this.changeFilter,
      parentChangeResource: this.changeResource,
      parentPollActionCompletion: this.pollActionCompletion
    }
  },
  data () {
    return {
      // WP Implementation
      getVgpuInfoResponse: '',
      hddOfferingList: [],
      ssdOfferingList: [],
      refreshCalled: 0,
      vpcOwnerAccUuid: String,
      proxyId: String,
      subAccounts: [],
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      domainUuid: this.$store.getters.userInfo.domainid,
      resourcePath: String,
      licences: false,
      selectedTagsInput: [],
      showCreateNewTagInput: false,
      targetTransferKeys: [],
      mockData: [],
      selectedKeys: [],
      transferInput: [],
      ipRequested: false,
      ipRequestedFail: false,
      inputAllLicenseFields: false,
      tagName: undefined,
      tagPrice: undefined,
      tagType: undefined,
      tagColor: undefined,
      specialChars: /[`´!@#$%^&*()+=[\]{};':"\\|,<>/?ß~]/,
      // /WP Implementation
      apiName: '',
      loading: false,
      actionLoading: false,
      columnKeys: [],
      allColumns: [],
      columns: [],
      bulkColumns: [],
      selectedColumns: [],
      chosenColumns: [],
      customColumnsDropdownVisible: false,
      showGroupActionModal: false,
      selectedItems: [],
      items: [],
      modalInfo: {},
      itemCount: 0,
      page: 1,
      pageSize: this.$store.getters.defaultListViewPageSize,
      resource: {},
      selectedRowKeys: [],
      currentAction: {},
      showAction: false,
      dataView: false,
      projectView: false,
      hasProjectId: false,
      selectedFilter: '',
      filters: [],
      searchFilters: [],
      searchParams: {},
      actions: [],
      confirmDirty: false,
      firstIndex: 0,
      modalWidth: '32vw',
      promises: []
    }
  },
  beforeUnmount () {
    eventBus.off('vm-refresh-data')
    eventBus.off('async-job-complete')
    eventBus.off('exec-action')
  },
  mounted () {
    eventBus.on('exec-action', (args) => {
      const { action, isGroupAction } = args
      this.execAction(action, isGroupAction)
    })
  },
  created () {
    this.formRef = ref()
    this.form = reactive({})
    this.rules = reactive({})
    this.getCustomPageSize()
    if (!this.$route.path.startsWith('/vpc/')) {
      eventBus.on('vm-refresh-data', () => {
        if (this.$route.path === '/vm' || this.$route.path.includes('/vm/')) {
          this.fetchData()
        }
      })
    }
    eventBus.on('refresh-icon', () => {
      if (this.$showIcon()) {
        this.fetchData()
      }
    })
    eventBus.on('async-job-complete', (action) => {
      if (this.$route.path.includes('/vm/')) {
        if (action && 'api' in action && ['destroyVirtualMachine'].includes(action.api)) {
          return
        }
      }

      if ((this.$route.path.includes('/publicip/') && ['firewall', 'portforwarding', 'loadbalancing'].includes(this.$route.query.tab)) ||
        (this.$route.path.includes('/guestnetwork/') && (this.$route.query.tab === 'egress.rules' || this.$route.query.tab === 'public.ip.addresses'))) {
        return
      }

      if (this.$route.path.includes('/template/') || this.$route.path.includes('/iso/')) {
        return
      }
      this.fetchData()
    })
    eventBus.on('update-bulk-job-status', (args) => {
      var { items, action } = args
      for (const item of items) {
        this.$store.getters.headerNotices.map(function (j) {
          if (j.jobid === item.jobid) {
            j.bulkAction = action
          }
        })
      }
    })

    eventBus.on('update-resource-state', (args) => {
      var {
        selectedItems,
        resource,
        state,
        jobid
      } = args
      if (selectedItems.length === 0) {
        return
      }
      var tempResource = []
      this.selectedItems = selectedItems
      if (selectedItems && resource) {
        if (resource.includes(',')) {
          resource = resource.split(',')
          tempResource = resource
        } else {
          tempResource.push(resource)
        }
        for (var r = 0; r < tempResource.length; r++) {
          var objIndex = 0
          if (this.$route.path.includes('/template') || this.$route.path.includes('/iso')) {
            objIndex = selectedItems.findIndex(obj => (obj.zoneid === tempResource[r]))
          } else if (this.$route.path.includes('/router')) {
            objIndex = selectedItems.findIndex(obj => (obj.guestnetworkid === tempResource[r]))
          } else {
            objIndex = selectedItems.findIndex(obj => (obj.id === tempResource[r] || obj.username === tempResource[r] || obj.name === tempResource[r]))
          }
          if (state && objIndex !== -1) {
            this.selectedItems[objIndex].status = state
          }
          if (jobid && objIndex !== -1) {
            this.selectedItems[objIndex].jobid = jobid
          }
        }
      }
    })
    // WP Implementation
    this.resourcePath = this.$route.fullPath
    this.currentPath = this.$route.fullPath
    this.fetchData()
    // needs to only be called for snapshots
    if (this.$route.path.startsWith('/snapshot')) {
      this.getAllTagsApi()
    }
    // /WP Implementation
    if ('projectid' in this.$route.query) {
      this.switchProject(this.$route.query.projectid)
    }
    this.setModalWidthByScreen()
  },
  beforeRouteUpdate (to, from, next) {
    this.currentPath = this.$route.fullPath
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.currentPath = this.$route.fullPath
    next()
  },
  watch: {
    '$route' (to, from) {
      // WP Implementation
      this.resourcePath = to.fullPath
      // /WP Implementation
      if (to.fullPath !== from.fullPath && !to.fullPath.includes('action/')) {
        if ('page' in to.query) {
          this.page = Number(to.query.page)
          this.pageSize = Number(to.query.pagesize)
        } else {
          this.page = 1
        }
        this.itemCount = 0
        this.fetchData()
        if ('projectid' in to.query) {
          this.switchProject(to.query.projectid)
        }
      }
    },
    '$i18n.locale' (to, from) {
      if (to !== from) {
        this.fetchData()
      }
    },
    '$store.getters.metrics' (oldVal, newVal) {
      this.fetchData()
    },
    '$store.getters.listAllProjects' (oldVal, newVal) {
      this.fetchData()
    }
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    },
    pageSizeOptions () {
      var sizes = [20, 50, 100, 200, this.$store.getters.defaultListViewPageSize]
      if (this.device !== 'desktop') {
        sizes.unshift(10)
      }
      return [...new Set(sizes)].sort(function (a, b) {
        return a - b
      }).map(String)
    }
  },
  methods: {
    // WP Implementation
    setAccountSetting (setting, input) {
      var params = new FormData()
      params.append('account_uuid', this.accountUuid)
      params.append('customSettings', setting)
      params.append('customData', input)
      api2.setCustomAccountSettings(params).then(response => {
        if (response.status === 200) {
          this.$notification.success({
            message: this.$t('label.success'),
            description: 'Successfully set custom data' + setting + ' to ' + input + '.'
          })
        } else {
          this.$notification.error({
            message: this.$t('label.error'),
            description: 'Error setting custom data ' + setting + '.'
          })
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'Error setting custom data ' + setting + '.'
        })
      })
    },
    getCustomPageSize () {
      api2.getCustomAccountSettings(this.accountUuid, 'default_pagnation ').then(response => {
        if (response.status === 200 && response.data !== undefined && response.data !== 'no entry') {
          this.pageSize = response.data
        } else {
          this.pageSize = this.$store.getters.defaultListViewPageSize
        }
      })
    },
    fetchVgpu () {
      var vgpuInput = new FormData()
      vgpuInput.append('vmuuid', this.resource.id)
      api2.getAllocatedGpu(vgpuInput).then(response => {
        this.getVgpuInfoResponse = response.data
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'Error fetching GPU State of this VM'
        })
      })
    },
    validateAccountName (rule, value) {
      const errorMessage = this.$t('message.validate.special.characters')
      if (this.specialChars.test(value) === false) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    autoRequestIps () {
      var params = new FormData()
      params.append('customer', this.accountName)
      params.append('email', this.$store.getters.userInfo.email)
      api2.requestNewIpRange(params).then(response => {
        if (response.status === 200) {
          this.ipRequested = true
          console.log('IP Requested via Mail!')
        } else {
          this.ipRequestedFail = true
        }
      }).catch(error => {
        this.$notifyError(error)
        this.ipRequestedFail = true
      })
    },
    // /WP Implementation
    getStyle () {
      if (['snapshot', 'vmsnapshot', 'publicip'].includes(this.$route.name)) {
        return 'inline-flex'
      }
      return 'inline-flex'
    },
    getOkProps () {
      if (this.selectedRowKeys.length > 0 && this.currentAction?.groupAction) {
        return { props: { type: 'default' } }
      } else {
        return { props: { type: 'primary' } }
      }
    },
    getCancelProps () {
      if (this.selectedRowKeys.length > 0 && this.currentAction?.groupAction) {
        return { props: { type: 'primary' } }
      } else {
        return { props: { type: 'default' } }
      }
    },
    switchProject (projectId) {
      if (!projectId || !projectId.length || projectId.length !== 36) {
        return
      }
      api('listProjects', { id: projectId, listall: true, details: 'min' }).then(json => {
        if (!json || !json.listprojectsresponse || !json.listprojectsresponse.project) return
        const project = json.listprojectsresponse.project[0]
        this.$store.dispatch('SetProject', project)
        this.$store.dispatch('ToggleTheme', project.id === undefined ? 'light' : 'dark')
        this.$message.success(`${this.$t('message.switch.to')} "${project.name}"`)
        const query = Object.assign({}, this.$route.query)
        delete query.projectid
        this.$router.replace({ query })
      })
    },
    // WP Implementation
    async fetchResellerCustomerAccounts () {
      await wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
        this.subAccounts = response.data.filter(x => x.state === 'enabled' && x.removed === null)
      }).catch(error => {
        console.log(error)
      })
    },
    // /WP Implementation
    async fetchData (params = {}) {
      this.refreshCalled += 1
      if (this.$route.name === 'deployVirtualMachine') {
        return
      }
      if (this.routeName !== this.$route.name) {
        this.routeName = this.$route.name
        this.items = []
      }
      if (!this.routeName) {
        this.routeName = this.$route.matched[this.$route.matched.length - 1].meta.name
      }
      this.apiName = ''
      this.actions = []
      this.columns = []
      this.columnKeys = []
      this.selectedColumns = []
      const refreshed = ('irefresh' in params)

      params.listall = true
      if (this.$route.meta.params) {
        const metaParams = this.$route.meta.params
        if (typeof metaParams === 'function') {
          Object.assign(params, metaParams())
        } else {
          Object.assign(params, metaParams)
        }
      }
      if (['Admin', 'DomainAdmin'].includes(this.$store.getters.userInfo.roletype) &&
        'templatefilter' in params && this.routeName === 'template') {
        params.templatefilter = 'all'
      }
      if (['Admin', 'DomainAdmin'].includes(this.$store.getters.userInfo.roletype) &&
        'isofilter' in params && this.routeName === 'iso') {
        params.isofilter = 'all'
      }
      if (Object.keys(this.$route.query).length > 0) {
        if ('page' in this.$route.query) {
          this.page = Number(this.$route.query.page)
        }
        if ('pagesize' in this.$route.query) {
          this.pageSize = Number(this.$route.query.pagesize)
        }
        Object.assign(params, this.$route.query)
      }
      delete params.q
      delete params.filter
      delete params.irefresh

      this.searchFilters = this.$route && this.$route.meta && this.$route.meta.searchFilters
      this.filters = this.$route && this.$route.meta && this.$route.meta.filters
      if (typeof this.filters === 'function') {
        this.filters = this.filters()
      }

      this.projectView = Boolean(store.getters.project && store.getters.project.id)
      this.hasProjectId = ['vm', 'vmgroup', 'ssh', 'affinitygroup', 'volume', 'snapshot', 'vmsnapshot', 'guestnetwork', 'vpc', 'securitygroups', 'publicip', 'vpncustomergateway', 'template', 'iso', 'event', 'kubernetes'].includes(this.$route.name)

      if ((this.$route && this.$route.params && this.$route.params.id) || this.$route.query.dataView) {
        this.dataView = true
        if (!refreshed) {
          this.resource = {}
          this.$emit('change-resource', this.resource)
        }
      } else {
        this.dataView = false
      }

      if ('listview' in this.$refs && this.$refs.listview) {
        this.$refs.listview.resetSelection()
      }

      if (this.$route && this.$route.meta && this.$route.meta.permission) {
        this.apiName = this.$route.meta.permission[0]
        if (this.$route.meta.columns) {
          const columns = this.$route.meta.columns
          if (columns && typeof columns === 'function') {
            this.columnKeys = columns(this.$store.getters)
          } else {
            this.columnKeys = columns
          }
        }

        if (this.$route.meta.actions) {
          this.actions = this.$route.meta.actions
        }
      }

      if (this.apiName === '' || this.apiName === undefined) {
        return
      }

      if (!this.columnKeys || this.columnKeys.length === 0) {
        for (const field of store.getters.apis[this.apiName].response) {
          this.columnKeys.push(field.name)
        }
        this.columnKeys = [...new Set(this.columnKeys)]
        this.columnKeys.sort(function (a, b) {
          if (a === 'name' && b !== 'name') { return -1 }
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
      }

      const customRender = {}
      for (var columnKey of this.columnKeys) {
        let key = columnKey
        let title = columnKey === 'cidr' && this.columnKeys.includes('ip6cidr') ? 'ipv4.cidr' : columnKey
        if (typeof columnKey === 'object') {
          if ('customTitle' in columnKey && 'field' in columnKey) {
            key = columnKey.customTitle
            title = columnKey.customTitle
            customRender[key] = columnKey[key]
          } else {
            key = Object.keys(columnKey)[0]
            title = Object.keys(columnKey)[0]
            customRender[key] = columnKey[key]
          }
        }
        this.columns.push({
          title: this.$t('label.' + String(title).toLowerCase()),
          dataIndex: key,
          slots: { customRender: key },
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        })
        this.selectedColumns.push(key)
      }
      this.allColumns = this.columns

      if (!store.getters.metrics) {
        if (!this.$store.getters.customColumns[this.$store.getters.userInfo.id]) {
          this.$store.getters.customColumns[this.$store.getters.userInfo.id] = {}
          this.$store.getters.customColumns[this.$store.getters.userInfo.id][this.$route.path] = this.selectedColumns
        } else {
          this.selectedColumns = this.$store.getters.customColumns[this.$store.getters.userInfo.id][this.$route.path] || this.selectedColumns
          this.updateSelectedColumns()
        }
      }

      this.chosenColumns = this.columns.filter(column => {
        return ![this.$t('label.state'), this.$t('label.hostname'), this.$t('label.hostid'), this.$t('label.zonename'),
          this.$t('label.zone'), this.$t('label.zoneid'), this.$t('label.ip'), this.$t('label.ipaddress'), this.$t('label.privateip'),
          this.$t('label.linklocalip'), this.$t('label.size'), this.$t('label.sizegb'), this.$t('label.current'),
          this.$t('label.created'), this.$t('label.order')].includes(column.title)
      })

      if (['listTemplates', 'listIsos'].includes(this.apiName) && this.dataView) {
        delete params.showunique
      }

      this.loading = true
      if (this.$route.params && this.$route.params.id) {
        params.id = this.$route.params.id
        if (['listSSHKeyPairs'].includes(this.apiName)) {
          if (!this.$isValidUuid(params.id)) {
            delete params.id
            params.name = this.$route.params.id
          }
        }
        if (['listPublicIpAddresses'].includes(this.apiName)) {
          params.allocatedonly = false
        }
        if (this.$route.path.startsWith('/vmsnapshot/')) {
          params.vmsnapshotid = this.$route.params.id
          // WP Implementation
          if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.params.account) {
            params.account = this.$route.params.account
            params.domainid = this.$route.params.domainid
          }
          // /WP Implementation
        } else if (this.$route.path.startsWith('/ldapsetting/')) {
          params.hostname = this.$route.params.id
        }
      }

      if (this.$store.getters.listAllProjects && !this.projectView) {
        params.projectid = '-1'
      }

      params.page = this.page
      params.pagesize = this.pageSize

      if (this.$showIcon()) {
        params.showIcon = true
      }

      await api(this.apiName, params).then(async json => {
        var responseName
        var objectName
        for (const key in json) {
          if (key.includes('response')) {
            responseName = key
            break
          }
        }
        this.itemCount = 0
        for (const key in json[responseName]) {
          if (key === 'count') {
            this.itemCount = json[responseName].count
            continue
          }
          objectName = key
          break
        }
        this.items = json[responseName][objectName]
        // WP Implementation
        // call list Volumes to display the disk Data for each VM in the Instance list
        if (['listVirtualMachinesMetrics'].includes(this.apiName) && (this.$route.path.startsWith('/vm') && !this.$route.path.startsWith('/vm/'))) {
          await api2.getDiskofferingList().then(response => {
            if (response.data) {
              this.hddOfferingList = response.data.HDD
              this.ssdOfferingList = response.data.SSD
            } else {
              console.log('Error fetching DiskOffering List!')
            }
          }).catch((error) => {
            console.log(error)
          })
          api('listVolumes', { listall: true }).then(async json => {
            var volumes = json.listvolumesresponse.volume
            if (volumes.length !== 0 || volumes !== undefined) {
              for (var vm of this.items) {
                var ssd = 0
                var hdd = 0
                var volumesOfVm = volumes.filter(volume => volume.virtualmachineid === vm.id)
                if (volumesOfVm.length !== 0) {
                  for (var volume of volumesOfVm) {
                    if (volume.diskofferingid && this.hddOfferingList.some(params => params.uuid === volume.diskofferingid)) {
                      hdd += (volume.size)
                    } else if (volume.diskofferingid && this.ssdOfferingList.some(params => params.uuid === volume.diskofferingid)) {
                      ssd += (volume.size)
                    } else { ssd += (volume.size) }
                  }
                }
                if (hdd === 0 && ssd === 0) {
                  return
                } else if (hdd === 0 && ssd !== 0) {
                  vm.disksize = (ssd / 1024 / 1024 / 1024) + ' SSD'
                } else if (hdd !== 0 && ssd === 0) {
                  vm.disksize = (hdd / 1024 / 1024 / 1024) + ' HDD'
                } else { vm.disksize = (ssd / 1024 / 1024 / 1024 + ' SSD, ' + hdd / 1024 / 1024 / 1024 + ' HDD') }
              }
            }
          }).catch(error => {
            console.log(error)
          })
        }
        // listPublicIpAddresses does not show Free IpAdresses somehow. We implemented a publicIPAdresses func in API2 that handles subAccounts and freeIPs!
        // params.id === undefined makes sure that this logic is not executed in resourceView
        if (this.apiName === 'listPublicIpAddresses' && this.$route.path.startsWith('/publicip') && params.id === undefined && (this.$store.getters.userInfo.rolename === 'ResellerAdmin' || this.$store.getters.userInfo.roletype === 'Admin')) {
          this.items = []
          await api2.publicIPAdresses(this.accountUuid).then(response => {
            this.items = response.data
          }).catch(error => {
            this.$notification.error({
              message: this.$t('label.error.ipaddress'),
              description: error.response.data,
              duration: 0
            })
            console.log(error.response)
          })
        }
        // listall=true is not working for vm-Snapshots. This is a complicated workaround to display all vmSnapshots of subAccounts in the ResellerAdmin Listview!
        if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.path.startsWith('/vmsnapshot')) {
          await this.fetchResellerCustomerAccounts()
          this.subAccounts.forEach(async subaccount => {
            var params = { account: subaccount.accountName, domainid: subaccount.domainUuid }
            await api('listVMSnapshot', params).then(async json => {
              const response = json.listvmsnapshotresponse.vmSnapshot
              if (response !== undefined) {
                this.items.push(...response)
              }
            }).catch(error => {
              console.log(error)
            })
          })
        }
        // /WP Implementation
        if (!this.items || this.items.length === 0) {
          this.items = []
        }

        if (['listTemplates', 'listIsos'].includes(this.apiName) && this.items.length > 1) {
          this.items = [...new Map(this.items.map(x => [x.id, x])).values()]
        }

        if (this.apiName === 'listProjects' && this.items.length > 0) {
          this.columns.map(col => {
            if (col.title === 'Account') {
              col.title = this.$t('label.project.owner')
            }
          })
        }

        if (this.apiName === 'listAnnotations') {
          this.columns.map(col => {
            if (col.title === 'label.entityid') {
              col.title = this.$t('label.annotation.entity')
            } else if (col.title === 'label.entitytype') {
              col.title = this.$t('label.annotation.entity.type')
            } else if (col.title === 'label.adminsonly') {
              col.title = this.$t('label.annotation.admins.only')
            }
          })
        }

        for (let idx = 0; idx < this.items.length; idx++) {
          this.items[idx].key = idx
          for (const key in customRender) {
            const func = customRender[key]
            if (func && typeof func === 'function') {
              this.items[idx][key] = func(this.items[idx])
            }
          }
          if (this.$route.path.startsWith('/ldapsetting')) {
            this.items[idx].id = this.items[idx].hostname
          }
        }
        if (this.items.length > 0) {
          if (!this.showAction || this.dataView) {
            this.resource = this.items[0]
            this.$emit('change-resource', this.resource)
          }
        } else {
          if (this.dataView) {
            this.$router.push({ path: '/exception/404' })
          }
        }
      }).catch(error => {
        if ([401].includes(error.response.status)) {
          return
        }

        if (Object.keys(this.searchParams).length > 0) {
          this.itemCount = 0
          this.items = []
          this.$message.error({
            content: error.response.headers['x-description'],
            duration: 5
          })
          return
        }

        this.$notifyError(error)

        if ([405].includes(error.response.status)) {
          this.$router.push({ path: '/exception/403' })
        }

        if ([430, 431, 432].includes(error.response.status)) {
          this.$router.push({ path: '/exception/404' })
        }

        if ([530, 531, 532, 533, 534, 535, 536, 537].includes(error.response.status)) {
          this.$router.push({ path: '/exception/500' })
        }
      }).finally(f => {
        this.loading = false
        this.searchParams = params
      })
      if (this.$route.path.startsWith('/vm/')) {
        this.fetchVgpu()
      }
    },
    closeAction () {
      this.actionLoading = false
      this.showAction = false
      this.currentAction = {}
      this.licences = false
      // WP Implementation
      this.showCreateNewTagInput = false
      // reset Tag Data if user = Admin and the createTemplate Form is closed
      if (this.$store.getters.userInfo.roletype === 'Admin') {
        this.getAllTagsApi()
      }
      // /WP Implementation
    },
    onRowSelectionChange (selection) {
      this.selectedRowKeys = selection
      if (selection?.length > 0) {
        this.modalWidth = '50vw'
        this.selectedItems = (this.items.filter(function (item) {
          return selection.indexOf(item.id) !== -1
        }))
      } else {
        this.modalWidth = '32vw'
      }

      this.setModalWidthByScreen()
    },
    execAction (action, isGroupAction) {
      const self = this
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({})
      if (action.component && action.api && !action.popup) {
        const query = {}
        if (this.$route.path.startsWith('/vm')) {
          switch (true) {
            case ('templateid' in this.$route.query):
              query.templateid = this.$route.query.templateid
              break
            case ('isoid' in this.$route.query):
              query.isoid = this.$route.query.isoid
              break
            case ('networkid' in this.$route.query):
              query.networkid = this.$route.query.networkid
              break
            default:
              break
          }
        }
        this.$router.push({ name: action.api, query })
        return
      }
      this.currentAction = action
      // console.log(store.getters.apis[this.currentAction.api].params)
      if (action.api === 'allocateVgpu' || action.api === 'delocateVgpu') {
        this.currentAction.params = [{
          description: 'which GPU do you want to attach?',
          name: 'vGPU',
          length: 255,
          required: true,
          type: 'uuid'
        }]
      } else if (action.api === 'createSaasManager') {
        this.currentAction.params = [{
          description: 'test',
          name: 'domain',
          length: 255,
          required: true,
          type: 'string'
        },
        {
          description: 'test',
          name: 'shortName',
          length: 255,
          required: true,
          type: 'string'
        }
        ]
      } else {
        this.currentAction.params = store.getters.apis[this.currentAction.api].params
      }
      this.resource = action.resource
      this.$emit('change-resource', this.resource)
      var paramFields = this.currentAction.params
      paramFields.sort(function (a, b) {
        if (a.name === 'name' && b.name !== 'name') { return -1 }
        if (a.name !== 'name' && b.name === 'name') { return -1 }
        if (a.name === 'id') { return -1 }
        if (a.name < b.name) { return -1 }
        if (a.name > b.name) { return 1 }
        return 0
      })
      this.currentAction.paramFields = []
      if ('message' in action) {
        var message = action.message
        if (typeof action.message === 'function') {
          message = action.message(action.resource)
        }
        action.message = message
      }
      if ('args' in action) {
        var args = action.args
        if (typeof action.args === 'function') {
          args = action.args(action.resource, this.$store.getters, isGroupAction)
        }
        if (args.length > 0) {
          this.currentAction.paramFields = args.map(function (arg) {
            if (arg === 'confirmpassword') {
              return {
                type: 'password',
                name: 'confirmpassword',
                required: true,
                description: self.$t('label.confirmpassword.description')
              }
            }
            return paramFields.filter(function (param) {
              return param.name.toLowerCase() === arg.toLowerCase()
            })[0]
          })
        }
      }
      this.getFirstIndexFocus()

      this.showAction = true
      // WP Implementation
      this.selectedTagsInput = []
      // /WP Implementation
      const listIconForFillValues = ['copy-outlined', 'CopyOutlined', 'edit-outlined', 'EditOutlined', 'share-alt-outlined', 'ShareAltOutlined']
      for (const param of this.currentAction.paramFields) {
        if (param.type === 'list' && ['tags', 'hosttags', 'storagetags', 'files'].includes(param.name)) {
          param.type = 'string'
        }
        this.setRules(param)
        if (param.type === 'uuid' || param.type === 'list' || param.name === 'account' || (this.currentAction.mapping && param.name in this.currentAction.mapping)) {
          this.listUuidOpts(param)
        }
      }
      this.actionLoading = false
      if (action.dataView && listIconForFillValues.includes(action.icon)) {
        this.fillEditFormFieldValues()
      }
    },
    getFirstIndexFocus () {
      this.firstIndex = 0
      for (let fieldIndex = 0; fieldIndex < this.currentAction.paramFields.length; fieldIndex++) {
        const field = this.currentAction.paramFields[fieldIndex]
        if (!(this.currentAction.mapping && field.name in this.currentAction.mapping && this.currentAction.mapping[field.name].value)) {
          this.firstIndex = fieldIndex
          break
        }
      }
    },
    listUuidOpts (param) {
      if (this.currentAction.mapping && param.name in this.currentAction.mapping && !this.currentAction.mapping[param.name].api) {
        return
      }
      var paramName = param.name
      var extractedParamName = paramName.replace('ids', '').replace('id', '').toLowerCase()
      var params = { listall: true }
      const possibleName = 'list' + extractedParamName + 's'
      var showIcon = false
      if (this.$showIcon(extractedParamName)) {
        showIcon = true
      }
      var possibleApi
      if (this.currentAction.mapping && param.name in this.currentAction.mapping && this.currentAction.mapping[param.name].api) {
        possibleApi = this.currentAction.mapping[param.name].api
        if (this.currentAction.mapping[param.name].params) {
          const customParams = this.currentAction.mapping[param.name].params(this.resource)
          if (customParams) {
            params = { ...params, ...customParams }
          }
        }
      } else if (paramName === 'id') {
        possibleApi = this.apiName
      } else {
        for (const api in store.getters.apis) {
          if (api.toLowerCase().startsWith(possibleName)) {
            possibleApi = api
            break
          }
        }
      }
      if (!possibleApi) {
        return
      }
      param.loading = true
      param.opts = []
      if (possibleApi === 'listTemplates') {
        params.templatefilter = 'executable'
      } else if (possibleApi === 'listIsos') {
        params.isofilter = 'executable'
      } else if (possibleApi === 'listHosts') {
        params.type = 'routing'
      } else if (possibleApi === 'listNetworkOfferings' && this.resource) {
        if (this.resource.type) {
          params.guestiptype = this.resource.type
        }
        if (!this.resource.vpcid) {
          params.forvpc = false
        }
      }
      if (showIcon) {
        params.showicon = true
      }
      api(possibleApi, params).then(json => {
        param.loading = false
        for (const obj in json) {
          if (obj.includes('response')) {
            for (const res in json[obj]) {
              if (res === 'count') {
                continue
              }
              param.opts = json[obj][res]
              if (this.currentAction.mapping && this.currentAction.mapping[param.name] && this.currentAction.mapping[param.name].filter) {
                const filter = this.currentAction.mapping[param.name].filter
                param.opts = json[obj][res].filter(filter)
              }
              if (['listTemplates', 'listIsos'].includes(possibleApi)) {
                param.opts = [...new Map(param.opts.map(x => [x.id, x])).values()]
              }
              break
            }
            break
          }
        }
      }).catch(function (error) {
        console.log(error)
        param.loading = false
      })
    },
    pollActionCompletion (jobId, action, resourceName, resource, showLoading = true) {
      if (this.shouldNavigateBack(action)) {
        action.isFetchData = false
      }
      return new Promise((resolve) => {
        this.$pollJob({
          jobId,
          title: this.$t(action.label),
          description: resourceName,
          name: resourceName,
          successMethod: async result => {
            // WP Implementation
            // call WP Apis at this point if they are defined in storage or computed without a connected component!
            if (action.api === 'createTemplate') {
              console.log('Current API is createTemplate')
              // when creating a template from volume: volumeuuid = action.resource
              if (action.resource.volumeid) {
                // case from Snapshot
                this.createTemplate(result.jobresult.template.id, result.jobresult.template.name, action.resource.volumeid, this.resourcePath)
              } else {
                // case from volume
                this.createTemplate(result.jobresult.template.id, result.jobresult.template.name, action.resource.id, this.resourcePath)
              }
            } else if (action.api === 'revertSnapshot') {
              wpapi.revertVmToSnapshot(action.resource.id).then(response => {
                console.log('[WP REVERT TO SNAPSHOT]')
              })
                .catch(error => {
                  console.log(error)
                  this.$notifyError(error)
                })
            } else if (action.api === 'restoreVirtualMachine') {
              wpapi.reinstallVmFromTemplate(result.jobresult.virtualmachine.templateid, action.resource.id).then(response => {
                console.log('[WP REINSTALL VM FROM TEMPLATE]')
              })
                .catch(error => {
                  console.log(error)
                  this.$notifyError(error)
                })
            } else if (action.api === 'createVMSnapshot') {
              // VM Snapshots
              const volumeId = action.resource.volumes.filter(x => x.type === 'ROOT')[0].id
              const vmId = result.jobresult.vmsnapshot.virtualmachineid
              const snapshotId = result.jobresult.vmsnapshot.id
              var params = new FormData()
              params.append('snapshotuuid', snapshotId)
              params.append('vmuuid', vmId)
              params.append('volumeuuid', volumeId)
              wpapi.createVmSnapshot(params).then(response => {
                console.log('WP VM Snapshot was sucessfully created')
              })
              const message = `${this.$t('label.create.snapshot.for.volume')} ${volumeId} ${this.$t('label.with.snapshotid')} ${snapshotId}`
              this.$notification.success({
                message: message,
                duration: 0
              })
            } else if (action.api === 'deleteVPC') {
              // get Account ID of VPC owner
              await this.getVpcOwnerAccId(action.resource.account)
              // get proxyid
              var getProxyparams = new FormData()
              getProxyparams.append('accountid', this.vpcOwnerAccUuid)
              getProxyparams.append('vpcid', action.resource.id)
              await api2.getProxySettings(getProxyparams).then(response => {
                this.proxyId = response.data != null ? response.data.proxyid : ''
              }).catch(error => {
                console.log(error)
                this.$notification.error({
                  message: this.$t('label.error'),
                  description: this.$t('error.proxySettings')
                })
              })
              // delete Proxy
              var deleteProxyParams = new FormData()
              deleteProxyParams.append('proxyid', this.proxyId)
              await api2.deleteProxy(deleteProxyParams).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
            } else if (action.api === 'deleteAccount') {
              // call deleteAcronis USer after account deletion
              var executeDeleteAcronisUser = false
              var getUserParams = new FormData()
              getUserParams.append('account', action.resource.id)
              await api2.getAcronisUser(getUserParams).then(response => {
                if (response.data.status === 'ok') {
                  executeDeleteAcronisUser = true
                } else { executeDeleteAcronisUser = false }
              }).catch(error => {
                this.$notifyError(error)
              })
              if (executeDeleteAcronisUser) {
                var delAcronisUserParams = new FormData()
                delAcronisUserParams.append('account', action.resource.id)
                api2.deleteAcronisUser(delAcronisUserParams).then(response => {
                  if (response.data.status === 'ok') {
                    const message = `${this.$t('message.success.delete.acronis.user')} ${action.resource.id}`
                    this.$notification.success({
                      message: message,
                      duration: 0
                    })
                  }
                }).catch(error => {
                  this.$notifyError(error)
                })
              }
            } else if (action.api === 'allocateVgpu' || action.api === 'delocateVgpu') {
              this.fetchVgpu()
            }
            // /WP Implementation
            if (this.selectedItems.length > 0) {
              eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource, state: 'success' })
            }
            if (action.response) {
              const description = action.response(result.jobresult)
              if (description) {
                this.$notification.info({
                  message: this.$t(action.label),
                  description: (<span v-html={description}></span>),
                  duration: 0
                })
              }
            }
            if ('successMethod' in action) {
              action.successMethod(this, result)
            }
            resolve(true)
          },
          errorMethod: (job) => {
            if (this.selectedItems.length > 0) {
              eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource, state: 'failed' })
            }
            if (action.api === 'detachVolume' && job.jobresult.errortext.includes('org.libvirt.LibvirtException: Domain not found: no domain with matching name')) {
              setTimeout(this.callDetachErrorMessage, 500)
            }
            resolve(true)
          },
          loadingMessage: `${this.$t(action.label)} - ${resourceName}`,
          showLoading: showLoading,
          catchMessage: this.$t('error.fetching.async.job.result'),
          action,
          bulkAction: `${this.selectedItems.length > 0}` && this.showGroupActionModal,
          resourceId: resource
        })
      })
    },
    callDetachErrorMessage () {
      this.$notification.warning({
        description: this.$t('message.detach.disk.error'),
        duration: 0
      })
    },
    fillEditFormFieldValues () {
      this.currentAction.paramFields.map(field => {
        let fieldValue = null
        let fieldName = null
        if (field.type === 'list' || field.name === 'account') {
          fieldName = field.name.replace('ids', 'name').replace('id', 'name')
        } else {
          fieldName = field.name
        }
        fieldValue = this.resource[fieldName] ? this.resource[fieldName] : null
        if (fieldValue) {
          this.form[field.name] = fieldValue
        }
      })
    },
    handleCancel () {
      eventBus.emit('update-bulk-job-status', { items: this.selectedItems, action: false })
      this.showGroupActionModal = false
      this.selectedItems = []
      this.bulkColumns = []
      this.selectedRowKeys = []
      this.message = {}
    },
    async handleSubmit (e) {
      if (this.currentAction.api === 'deleteVPC') {
        await this.getVpcOwnerAccId(this.currentAction.resource.account)
        // get proxyid
        var getProxyparams = new FormData()
        getProxyparams.append('accountid', this.vpcOwnerAccUuid)
        getProxyparams.append('vpcid', this.currentAction.resource.id)
        var getProxySettingsData
        await api2.getProxySettings(getProxyparams).then(response => {
          getProxySettingsData = response.data !== null ? response.data : null
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: this.$t('label.error'),
            description: this.$t('error.proxySettings')
          })
          getProxySettingsData = null
        })
        if (getProxySettingsData !== null) {
          this.showAction = false
          this.$notification.error({
            message: this.$t('label.cannot.delete.vpc'),
            description: this.$t('message.cannot.delete.vpc'),
            duration: 0
          })
          return
        }
      }
      if (this.actionLoading) return
      this.promises = []
      if (!this.dataView && this.currentAction.groupAction && this.selectedRowKeys.length > 0) {
        if (this.selectedRowKeys.length > 0) {
          this.bulkColumns = this.chosenColumns
          this.selectedItems = this.selectedItems.map(v => ({ ...v, status: 'InProgress' }))
          this.bulkColumns.splice(0, 0, {
            dataIndex: 'status',
            title: this.$t('label.operation.status'),
            slots: { customRender: 'status' },
            filters: [
              { text: 'In Progress', value: 'InProgress' },
              { text: 'Success', value: 'success' },
              { text: 'Failed', value: 'failed' }
            ]
          })
          this.showGroupActionModal = true
          this.modalInfo.title = this.currentAction.label
          this.modalInfo.docHelp = this.currentAction.docHelp
        }
        this.formRef.value.validate().then(() => {
          const values = toRaw(this.form)
          this.actionLoading = true
          const itemsNameMap = {}
          this.items.map(x => {
            itemsNameMap[x.id] = x.name || x.displaytext || x.id
          })
          const paramsList = this.currentAction.groupMap(this.selectedRowKeys, values, this.items)
          for (const params of paramsList) {
            var resourceName = itemsNameMap[params.id]
            // Using a method for this since it's an async call and don't want wrong prarms to be passed
            this.promises.push(this.callGroupApi(params, resourceName))
          }
          this.$message.info({
            content: this.$t(this.currentAction.label),
            key: this.currentAction.label,
            duration: 3
          })
          Promise.all(this.promises).finally(() => {
            this.actionLoading = false
            this.fetchData()
          })
        }).catch(error => {
          this.formRef.value.scrollToField(error.errorFields[0].name)
        })
      } else {
        await this.execSubmit(e)
        this.actionLoading = false
      }
    },
    callGroupApi (params, resourceName) {
      return new Promise((resolve, reject) => {
        const action = this.currentAction
        api(action.api, params).then(json => {
          resolve(this.handleResponse(json, resourceName, this.getDataIdentifier(params), action, false))
          this.closeAction()
        }).catch(error => {
          if ([401].includes(error.response.status)) {
            return
          }
          if (this.selectedItems.length !== 0) {
            this.$notifyError(error)
            eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource: this.getDataIdentifier(params), state: 'failed' })
          }
        })
      })
    },
    getDataIdentifier (params) {
      var dataIdentifier = ''
      dataIdentifier = params.id || params.username || params.name || params.vmsnapshotid || params.ids
      return dataIdentifier
    },
    handleResponse (response, resourceName, resource, action, showLoading = true) {
      return new Promise(resolve => {
        let jobId = null
        for (const obj in response) {
          if (obj.includes('response')) {
            if (response[obj].jobid) {
              jobId = response[obj].jobid
            } else {
              if (this.selectedItems.length > 0) {
                eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource, state: 'success' })
                if (resource) {
                  this.selectedItems.filter(item => item === resource)
                }
              }
              var message = action.successMessage ? this.$t(action.successMessage) : this.$t(action.label) +
                (resourceName ? ' - ' + resourceName : '')
              var duration = 2
              if (action.additionalMessage) {
                message = message + ' - ' + this.$t(action.successMessage)
                duration = 5
              }
              if (this.selectedItems.length === 0) {
                this.$message.success({
                  content: message,
                  key: action.label + resourceName,
                  duration: duration
                })
              }
              break
            }
          }
        }
        if (['addLdapConfiguration', 'deleteLdapConfiguration'].includes(action.api)) {
          this.$store.dispatch('UpdateConfiguration')
        }
        if (jobId) {
          eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource, state: 'InProgress', jobid: jobId })
          resolve(this.pollActionCompletion(jobId, action, resourceName, resource, showLoading))
        }
        resolve(false)
      })
    },
    // WP Implementation
    async execSubmit (e) {
      e.preventDefault()
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)
        this.selectedTagsInput = values.selectedTags
        const params = {}
        const action = this.currentAction
        if ('id' in this.resource && action.params.map(i => { return i.name }).includes('id')) {
          params.id = action.resource.id
          // WP Implementation
        }
        for (const key in values) {
          const input = values[key]
          for (const param of action.params) {
            if (param.name !== key) {
              continue
            }
            if (input === undefined || input === null ||
              (input === '' && !['updateStoragePool', 'updateHost', 'updatePhysicalNetwork', 'updateDiskOffering', 'updateNetworkOffering', 'updateServiceOffering'].includes(action.api))) {
              if (param.type === 'boolean') {
                params[key] = false
              }
              break
            }
            if (input === '' && !['tags', 'hosttags', 'storagetags'].includes(key)) {
              break
            }
            if (action.mapping && key in action.mapping && action.mapping[key].options) {
              params[key] = action.mapping[key].options[input]
            } else if (param.type === 'list') {
              params[key] = input.map(e => { return param.opts[e].id }).reduce((str, name) => { return str + ',' + name })
            } else if (param.name === 'account' || param.name === 'keypair') {
              if (['addAccountToProject', 'createAccount'].includes(action.api)) {
                params[key] = input
              } else {
                params[key] = param.opts[input].name
              }
            } else {
              params[key] = input
            }
            break
          }
        }

        for (const key in action.defaultArgs) {
          if (!params[key]) {
            params[key] = action.defaultArgs[key]
          }
        }

        if (!this.projectView || !['uploadSslCert'].includes(action.api)) {
          if (action.mapping) {
            for (const key in action.mapping) {
              if (!action.mapping[key].value) {
                continue
              }
              params[key] = action.mapping[key].value(this.resource, params)
            }
          }
        }
        // WP Implementation
        const resourceName = params.displayname || params.displaytext || params.name || params.hostname || params.username ||
          params.ipaddress || params.virtualmachinename || action.resource.name || action.resource.ipaddress || action.resource.id
        // /WP Implementation
        var hasJobId = false
        this.actionLoading = true
        let args = null
        if (action.post) {
          args = [action.api, {}, 'POST', params]
        } else {
          args = [action.api, params]
        }
        // WP Implementation
        await api(...args).then(json => {
          // /WP Implementation
          var response = this.handleResponse(json, resourceName, this.getDataIdentifier(params), action)
          if (!response) {
            this.fetchData()
            this.closeAction()
            return
          }
          response.then(jobId => {
            hasJobId = jobId
            if (this.shouldNavigateBack(action)) {
              this.$router.go(-1)
            } else {
              if (!hasJobId) {
                this.fetchData()
              }
            }
            if (action.api === 'revertToVMSnapshot') {
              var params = new FormData()
              params.append('vmuuid', action.resource.virtualmachineid)
              api2.getAllocatedGpu(params).then(response => {
                if (response.data !== '' && response.data !== false) {
                  api2.restoreGpuSettings(params).then(response => {
                  }).catch(error => {
                    console.log(error)
                    this.$notification.error({
                      message: this.$t('label.error'),
                      description: error.response.data
                    })
                  })
                }
              }).catch(error => {
                console.log(error)
                this.$notification.error({
                  message: this.$t('label.error'),
                  description: 'Error fetching GPU State of this VM'
                })
              })
            }
          })
          this.closeAction()
        }).catch(error => {
          if ([401].includes(error.response.status)) {
            return
          }
          console.log(error)
          eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource: this.getDataIdentifier(params), state: 'failed' })
          this.$notifyError(error)
        }).finally(f => {
          this.actionLoading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    shouldNavigateBack (action) {
      return ((['delete-outlined', 'DeleteOutlined'].includes(action.icon) || ['archiveEvents', 'archiveAlerts', 'unmanageVirtualMachine'].includes(action.api)) && this.dataView)
    },
    getColumnKey (name) {
      if (typeof name === 'object') {
        name = Object.keys(name).includes('customTitle') ? name.customTitle : name.field
      }
      return name
    },
    updateSelectedColumns (name) {
      if (name) {
        name = this.getColumnKey(name)
        if (this.selectedColumns.includes(name)) {
          this.selectedColumns = this.selectedColumns.filter(x => x !== name)
        } else {
          this.selectedColumns.push(name)
        }
      }

      this.columns = this.allColumns.filter(x => this.selectedColumns.includes(x.dataIndex))
      this.columns.push({
        dataIndex: 'dropdownFilter',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      })
      if (!this.$store.getters.customColumns[this.$store.getters.userInfo.id]) {
        this.$store.getters.customColumns[this.$store.getters.userInfo.id] = {}
      }
      this.$store.getters.customColumns[this.$store.getters.userInfo.id][this.$route.path] = this.selectedColumns
      this.$store.dispatch('SetCustomColumns', this.$store.getters.customColumns)
    },
    changeFilter (filter) {
      const query = Object.assign({}, this.$route.query)
      delete query.templatefilter
      delete query.isofilter
      delete query.account
      delete query.domainid
      delete query.state
      delete query.annotationfilter
      if (this.$route.name === 'template') {
        query.templatefilter = filter
      } else if (this.$route.name === 'iso') {
        query.isofilter = filter
      } else if (this.$route.name === 'guestnetwork') {
        if (filter === 'all') {
          delete query.networkfilter
        } else {
          query.networkfilter = filter
        }
      } else if (this.$route.name === 'publicip') {
        query.state = filter
      } else if (this.$route.name === 'vm') {
        if (filter === 'self') {
          query.account = this.$store.getters.userInfo.account
          query.domainid = this.$store.getters.userInfo.domainid
        } else if (['running', 'stopped'].includes(filter)) {
          query.state = filter
        }
      } else if (this.$route.name === 'comment') {
        query.annotationfilter = filter
      } else if (this.$route.name === 'guestvlans') {
        if (filter === 'all') {
          query.allocatedonly = 'false'
        } else if (filter === 'allocatedonly') {
          query.allocatedonly = 'true'
        }
      }
      query.filter = filter
      query.page = '1'
      query.pagesize = this.pageSize.toString()
      this.$router.push({ query })
    },
    onSearch (opts) {
      if (this.loading) return
      const query = Object.assign({}, this.$route.query)
      for (const key in this.searchParams) {
        delete query[key]
      }
      delete query.name
      delete query.templatetype
      delete query.keyword
      delete query.q
      this.searchParams = {}
      if (opts && Object.keys(opts).length > 0) {
        this.searchParams = opts
        if ('searchQuery' in opts) {
          const value = opts.searchQuery
          if (value && value.length > 0) {
            if (this.$route.name === 'role') {
              query.name = value
            } else if (this.$route.name === 'quotaemailtemplate') {
              query.templatetype = value
            } else if (this.$route.name === 'globalsetting') {
              query.name = value
            } else {
              query.keyword = value
            }
            query.q = value
          }
          this.searchParams = {}
        } else {
          Object.assign(query, opts)
        }
      }
      query.page = '1'
      query.pagesize = String(this.pageSize)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        this.fetchData(query)
        return
      }
      this.$router.push({ query })
    },
    changePage (page, pageSize) {
      const query = Object.assign({}, this.$route.query)
      query.page = page
      query.pagesize = pageSize
      this.$router.push({ query })
    },
    changePageSize (currentPage, pageSize) {
      this.setAccountSetting('default_pagnation', pageSize)
      const query = Object.assign({}, this.$route.query)
      query.page = currentPage
      query.pagesize = pageSize
      this.$router.push({ query })
    },
    changeResource (resource) {
      this.resource = resource
    },
    start () {
      this.loading = true
      this.fetchData()
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    startLoading () {
      this.loading = true
    },
    finishLoading () {
      this.loading = false
    },
    handleConfirmBlur (e, name) {
      if (name !== 'confirmpassword') {
        return
      }
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    async validateTwoPassword (rule, value) {
      if (!value || value.length === 0) {
        return Promise.resolve()
      } else if (rule.field === 'confirmpassword') {
        const messageConfirm = this.$t('message.validate.equalto')
        const passwordVal = this.form.password
        if (passwordVal && passwordVal !== value) {
          return Promise.reject(messageConfirm)
        } else {
          return Promise.resolve()
        }
      } else if (rule.field === 'password') {
        const confirmPasswordVal = this.form.confirmpassword
        if (!confirmPasswordVal || confirmPasswordVal.length === 0) {
          return Promise.resolve()
        } else if (value && this.confirmDirty) {
          this.formRef.value.validateFields('confirmpassword')
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.resolve()
      }
    },
    setRules (field) {
      let rule = {}

      if (!field || Object.keys(field).length === 0) {
        return
      }

      if (!this.rules[field.name]) {
        this.rules[field.name] = []
      }

      switch (true) {
        case (field.type === 'boolean'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)
          break
        case (this.currentAction.mapping && field.name in this.currentAction.mapping && 'options' in this.currentAction.mapping[field.name]):
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.name === 'keypair' || (field.name === 'account' && !['addAccountToProject', 'createAccount'].includes(this.currentAction.api))):
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.type === 'uuid'):
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.type === 'list'):
          rule.type = 'array'
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.type === 'long'):
          rule.type = 'number'
          rule.required = field.required
          rule.message = this.$t('message.validate.number')
          this.rules[field.name].push(rule)
          break
        case (field.name === 'password' || field.name === 'currentpassword' || field.name === 'confirmpassword'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)

          rule = {}
          rule.validator = this.validateTwoPassword
          this.rules[field.name].push(rule)
          break
        case (field.name === 'certificate' || field.name === 'privatekey' || field.name === 'certchain'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)
          break
        case (field.name === 'newname'):
          rule.required = field.required
          rule.validator = this.validateAccountName
          this.rules[field.name].push(rule)
          break
        default:
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)
          break
      }

      rule = {}
    },
    setModalWidthByScreen () {
      const screenWidth = window.innerWidth
      if (screenWidth <= 768) {
        this.modalWidth = '450px'
      }
    },
    // WP Implementation
    renderItem (item) {
      const customLabel = (
        <a-tag style="font-size: 8px;" color={item.colors} class="custom-item">
          {item.title}
        </a-tag>
      )

      return {
        label: customLabel, // for displayed item
        value: item.title // for title and filter matching
      }
    },
    handleSelectChange (sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys]
    },
    handleTransferChange (targetKeys) {
      this.targetTransferKeys = targetKeys
    },
    getAllTagsApi () {
      wpapi.getAllTags().then(response => {
        const targetKeys = []
        var allTags = response.data
        var tempTransferInput = []
        for (var tag in allTags) {
          const data = {
            key: allTags[tag].id.toString(),
            title: allTags[tag].tagText,
            colors: allTags[tag].tagColor,
            types: allTags[tag].tagType
          }
          tempTransferInput.push(data)
        }
        this.transferInput = tempTransferInput
        this.targetTransferKeys = targetKeys
      })
    },
    async createTemplate (newTemplateUuid, newTemplateName, volumeUuid, resourcePath) {
      var params = new FormData()
      // if admin seleted Tags for the new Template
      if (this.$store.getters.userInfo.roletype === 'Admin' && this.selectedTagsInput) {
        params.append('tagId', this.selectedTagsInput)
        // else if user creates a template who cant select Tags
      } else {
        params.append('tagId', '0')
      }
      params.append('pathName', resourcePath)
      params.append('templateName', newTemplateName)
      params.append('templateUuId', newTemplateUuid)
      params.append('volumeuuid', volumeUuid)
      wpapi.createRessourceTag(params).then(response => {
      })
        .catch(error => {
          this.$notifyError(error)
        })
    },
    createNewTag () {
      var params = new FormData()
      if (this.tagName === undefined || this.tagPrice === undefined || this.tagType === undefined || this.tagColor === undefined) {
        this.inputAllLicenseFields = true
        return
      }
      wpapi.createNewLicenceTag(params).then(response => {
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.getAllTagsApi()
      })
    },
    setCreateTagTrue () {
      if (this.showCreateNewTagInput) {
        this.showCreateNewTagInput = false
      } else {
        this.showCreateNewTagInput = true
      }
    },
    getUniques (object, tagAttribute) {
      switch (tagAttribute) {
        case 'color':
          var filteredColors = []
          for (var color in object) {
            if (!filteredColors.includes(object[color].colors)) {
              filteredColors.push(object[color].colors)
            }
          }
          return filteredColors

        case 'type':
          var filteredTypes = []
          for (var type in object) {
            if (!filteredTypes.includes(object[type].types)) {
              filteredTypes.push(object[type].types)
            }
          }
          return filteredTypes
      }
    },
    async getVpcOwnerAccId (accountName) {
      await api('listAccounts', { name: accountName }).then(response => {
        var responseAccUuid = response.listaccountsresponse.account.filter(account => account.name === accountName)[0].id
        this.vpcOwnerAccUuid = responseAccUuid !== undefined ? responseAccUuid : ''
      }).catch(error => {
        this.$notifyError(error)
        console.log(error)
      })
    },
    getPageSize (pageSize) {
      if (typeof pageSize === 'string') {
        return Number(pageSize)
      } else {
        return pageSize
      }
    }
    // /WP Implementation
  }
}
</script>

<style scoped>
.breadcrumb-card {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -16px;
  margin-bottom: 12px;
}

.row-element {
  margin-bottom: 10px;
}

.ant-breadcrumb {
  vertical-align: text-bottom;
}

:deep(.ant-alert-message) {
  display: flex;
  align-items: center;
}

.hide {
  display: none !important;
}
</style>
