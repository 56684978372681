<template>
  <a-spin :spinning="loading">
    <a-card class="spin-content no-padding no-outline sm-no-padding" :bordered="bordered" :title="title" id="infoCard">
      <!-- /WP Implementation -->
      <div>
        <a-row :gutter="12" class='instance-details instance-details-modal'>
          <a-col :md="24" :lg="24">
             <a-card :class="['instance-details-main', {'instance-details-vpcView' : this.$route.path.includes('/vpc/')}]">
               <div class='instance-details-main-body'>
                 <div class='instance-details-os-icon'>
                 <span v-if="resource.info.logo">
                  <img v-if="k8sTemplateImages.filter(x => x.file_name === resource.info.logo)[0] === undefined" src="../../assets/icons/docker-logo-black.png" style="height: 40px; width: 50px;"/>
                  <img v-else :src="'data:image/png;base64, ' + k8sTemplateImages.filter(x => x.file_name === resource.info.logo)[0].Content" style="height: 40px; width: 50px;"/>
                </span>
                   <a-tooltip placement="bottom">
                     <template #title>
                       {{ resource.group }}
                     </template>
                   <span class='instance-details-os-name'>{{resource.group}}</span>
                   </a-tooltip>
                 </div>
                <div>
                  <div class='instance-details-info'>
                    <div :class="[{'instance-details-info-itemVpc': this.$route.path.includes('/vpc/')}, {'instance-details-info-item': !this.$route.path.includes('/vpc/')}]" v-if="resource.replicacount">
                      <hdd-outlined />
                      <div :class="[{'instance-details-info-itemVpc-text': this.$route.path.includes('/vpc/')}, {'instance-details-info-item-text': !this.$route.path.includes('/vpc/')}]">
                        <span>{{ resource.replicacount }}</span>
                        <span class='instance-details-info-item-text-small'>{{ resource.replicacount === 1 ? $t('label.replikas.single') :  $t('label.replikas') }}</span>
                      </div>
                    </div>
                    <div :class="[{'instance-details-info-itemVpc': this.$route.path.includes('/vpc/')}, {'instance-details-info-item': !this.$route.path.includes('/vpc/')}]" v-if="resource.cpuLimit">
                      <img src="../../assets/icons-cloud/cpu.png" >
                      <div :class="[{'instance-details-info-itemVpc-text': this.$route.path.includes('/vpc/')}, {'instance-details-info-item-text': !this.$route.path.includes('/vpc/')}]">
                        <span>{{ (resource.cpuLimit) }}</span>
                        <span class='instance-details-info-item-text-small'>{{ $t('label.cpu') }}</span>
                      </div>
                    </div>
                    <div :class="[{'instance-details-info-itemVpc': this.$route.path.includes('/vpc/')}, {'instance-details-info-item': !this.$route.path.includes('/vpc/')}]"  v-if="resource.memLimit">
                      <img src="../../assets/icons-cloud/ram.png" >
                      <div :class="[{'instance-details-info-itemVpc-text': this.$route.path.includes('/vpc/')}, {'instance-details-info-item-text': !this.$route.path.includes('/vpc/')}]">
                        {{ resource.memLimit + ' GB' }}
                        <span class='instance-details-info-item-text-small'>{{ $t('label.memory') }}</span>
                      </div>
                    </div>
                    <div :class="[{'instance-details-info-itemVpc': this.$route.path.includes('/vpc/')}, {'instance-details-info-item': !this.$route.path.includes('/vpc/')}]"  v-if="resource.disksize">
                      <img src="../../assets/icons-cloud/disk.png" >
                      <div :class="[{'instance-details-info-itemVpc-text': this.$route.path.includes('/vpc/')}, {'instance-details-info-item-text': !this.$route.path.includes('/vpc/')}]">
                        <span>{{ resource.disksize + ' GB'}}</span>
                        <span class='instance-details-info-item-text-small'>{{ $t('label.disksizevpc') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class='instance-details-vpc-footer'>
                    <div class='instance-details-main-lizenz'>
                        <div style="display: flex;">
                          <a-collapse :bordered="true" accordion>
                            <a-collapse-panel class="openLicenses" key="1" :header="$t('label.license.click.to.show') + ' >'" >
                              <div class="resource-detail-item__details" v-if="resource.licenses.length >= 1">
                                <div>
                                  <a-list :grid="{ gutter: 20, lg: 2 }" :data-source="deploymentTags" :pagination="false">
                                    <template #renderItem="{item}">
                                      <a-list-item>
                                    <div
                                      v-for="taglicenses in deploymentTags"
                                      :key="taglicenses.licenseId">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" style="margin-bottom: 2px;" :color="item.color">{{ item.licenseName }}</a-tag>
                                      <br>
                                      <span style="margin-left: 1.5rem;">{{ taglicenses.count + ' '}} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === item.licenseName)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.count * discountList.filter(x => x.usageName === item.licenseName)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                  </a-list-item>
                                  </template>
                                  </a-list>
                                </div>
                              </div>
                              <!-- if no licenses are associated with the current VM -->
                              <div v-else>
                                {{ $t('message.no.associated.licenses') }}
                              </div>
                            </a-collapse-panel>
                          </a-collapse>
                        </div>
                    </div>
                    <div class='instance-details-vpc-id'>
                      <span><img src="../../assets/icons-cloud/barcode.png" > {{resource.deploymentid}}</span>
                    </div>
                  </div>
                </div>
               </div>
               <div class='instance-details-main-footer'>
                 <div :class="['instance-details-list', {'setWidth': this.$route.path.includes('/vpc/')}]">
                    <div class='instance-details-list-item' v-if="vpcOwnerAccUuid">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.account') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <team-outlined />
                       <div>
                         <router-link :to="{ path: '/account/' + vpcOwnerAccUuid }">{{ vpcResource.account }} </router-link>
                       </div>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.ipaddress">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.ipaddress') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <environment-outlined />
                       <div>
                        <span>{{ resource.ipaddress }}</span>
                       </div>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.deploymentpath">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.templatename') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <img src="../../assets/icons-cloud/camera.png" >
                       <div>
                        <span>{{ resource.deploymentpath }}</span>
                      </div>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.info.ports">
                     <div class='instance-details-list-item-title'>
                       {{ resource.info.ports.length > 1 ? $t('label.ports') : $t('label.port') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                        <global-outlined />
                        <a-popover v-if="resource.info.ports.length > 3">
                          <template #title>
                            {{ $t('label.ports') }}
                          </template>
                          <template #content>
                            <global-outlined />
                            <span v-for="(port, index) in resource.info.ports" :key="index">{{ index !== resource.info.ports.length-1 ? port.port + ', ' : port.port}}</span>
                          </template>
                          <div>
                            <span v-for="(port, index) in resource.info.ports" :key="index">{{ index !== resource.info.ports.length-1 ? port.port + ', ' : port.port}}</span>
                          </div>
                        </a-popover>
                        <div v-else>
                          <span v-for="(port, index) in resource.info.ports" :key="index">{{ index !== resource.info.ports.length-1 ? port.port + ', ' : port.port}}</span>
                        </div>
                     </div>
                   </div>
                   <div class="instance-details-list-item" v-if="resource.created">
                     <div class="instance-details-list-item-title">{{ $t('label.created') }}</div>
                     <div class="instance-details-list-item-value">
                       <calendar-outlined />{{ $toLocaleDate(resource.created) }}
                     </div>
                   </div>
                   <div class="instance-details-list-item" v-if="resource.updated">
                     <div class="instance-details-list-item-title">{{ $t('label.last.updated') }}</div>
                     <div class="instance-details-list-item-value">
                       <calendar-outlined />{{ $toLocaleDate(resource.updated) }}
                     </div>
                   </div>
                 </div>
               </div>
             </a-card>
          </a-col>
          <!-- Network Part of InstanceDetails Tab -->
          <a-col :md="24" :lg="24">
            <a-card :class="['instance-details-card', {'instance-details-vpcView' : this.$route.path.includes('/vpc/')}, {'instance-details-height' : this.$route.path.includes('/vpc/')}]">
              <div class='instance-details-card-header'>
                <img src="../../assets/icons-cloud/network.png" >
                {{ $t('label.network') }}
              </div>
              <div class='instance-details-card-body'>
                <a-list
                  v-if="networks && networks.length > 0 && this.$route.path.includes('/vpc/')"
                  :dataSource="networks"
                  size="small"
                  style="margin-top: 5px;"
                  :grid="{xxl: 2}"
                  :pagination="networks.length > pagination.pageSize ? pagination : false"
                >
                <template #renderItem="{index, item}">
                  <a-list-item :key="index">
                    <a-list-item-meta>
                      <template #title>
                        <div style="display: flex; flex-direction: row; margin-left: 20px;">
                          <img src='../../assets/icons-cloud/link.png' style="display: flex; justify-self: center; align-self: center; width: 14px; height: 14px; margin-right: 18px;">
                          <div>
                            <strong>{{ 'eth' + index }}</strong> {{ item.cidr }}
                            <br> <router-link v-if="item.name && item.id" :to="{ path: '/guestnetwork/' + item.id }">({{ item.name }})</router-link>
                          </div>
                        </div>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
/* WP Implementation */
import wpapi from '../../wpApi/myApi'
import { FileDoneOutlined, TeamOutlined, EnvironmentOutlined, GlobalOutlined, HddOutlined } from '@ant-design/icons-vue'
// import { reactive, ref } from 'vue'

export default {
  name: 'DeploymentInfoCard',
  components: {
    FileDoneOutlined,
    TeamOutlined,
    EnvironmentOutlined,
    GlobalOutlined,
    HddOutlined
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    bordered: {
      type: Boolean,
      default: true
    },
    vpcResource: {
      type: Object
    },
    vpcOwnerAccUuid: {
      type: String,
      required: true
    },
    k8sTemplateImages: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // WP Implementation
      accountUuid: this.$store.getters.userInfo.accountid,
      discountList: [],
      deploymentTags: [],
      networks: [],
      pagination: {
        onChange: page => {
          this.handleTableChange(page)
        },
        pageSize: 4,
        current: 1,
        total: 0
      }
    }
  },
  watch: {
    resource: {
      deep: false,
      handler (newData, oldData) {
        if (newData !== oldData) {
          this.getAllTagsApi()
        }
      }
    }
  },
  async mounted () {
    // WP Implementation
    if (this.$route.path.startsWith('/action/deployVirtualMachine') || this.$route.path.startsWith('/vm') || this.$route.path.startsWith('/vpc/') || this.$route.path.startsWith('/snapshot') || this.$route.path.startsWith('/volume')) {
      if (!['Admin'].includes(this.$store.getters.userInfo.roletype)) {
        await this.getDiscountList()
      }
    }
    this.fetchNetworks()
    this.getAllTagsApi()
    // /WP Implementation
  },
  methods: {
    // get ALL possible Tags from the Tag DB
    getAllTagsApi () {
      wpapi.getAllTags().then(response => {
        var allTags = response.data
        this.deploymentTags = this.resource.licenses
        var tempTransferInput = []
        for (var tag in allTags) {
          const data = {
            key: allTags[tag].id,
            title: allTags[tag].tagText,
            colors: allTags[tag].tagColor,
            types: allTags[tag].tagType
          }
          tempTransferInput.push(data)
        }
        this.tags = tempTransferInput
        for (var deployTags in this.deploymentTags) {
          this.deploymentTags[0].color = this.tags.filter(x => x.usageName === deployTags.licenseName)[0].colors
        }
      })
    },
    async getDiscountList () {
      await wpapi.discountListApi(this.accountUuid).then(response => {
        this.discountList = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
    },
    fetchNetworks () {
      this.networks = this.vpcResource.network.filter(x => x.id === this.resource.networkid)
      this.pagination.total = this.networks.length
    },
    handleTableChange (page) {
      if (this.$route.path.includes('/vpc/')) {
        this.pagination.current = page
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.openLicenses {
  position: absolute;
  width: 630px;
  z-index: 1;
}

.cyberCloudLink:hover {
  color: #3D7CAF;
  cursor: pointer;
}
.tagToRight {
  margin-left: 8px;
}
.shieldIcon {
  width:1.5rem;
  height:1.7rem;
  display: flex;
  justify-self: center;
  margin-right: 10px;
}
.bold {
  font-weight: bold;
}

.sliderAndInput {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
:deep(.ant-card-body) {
  padding: 5px 5px 0 0;
}

.resource-details {
  text-align: center;
  margin-bottom: 20px;

  &__name {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 20px;
      overflow: hidden;
      min-width: 50px;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .name {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1;
      word-break: break-all;
      text-align: left;
    }

  }
}
.resource-detail-item {
  margin-bottom: 20px;
  word-break: break-all;

  &__details {
    display: flex;
    align-items: center;

    &--start {
      align-items: flex-start;

      i {
        margin-top: 4px;
      }

    }

  }

  .anticon {
    margin-right: 10px;
  }

  &__label {
    margin-bottom: 5px;
    font-weight: bold;
  }

}
.user-keys {
  word-wrap: break-word;
}
.account-center-tags {
  .ant-tag {
    margin-bottom: 8px;
  }

  a {
    display: block;
    margin-bottom: 10px;
  }

}
.title {
  margin-bottom: 5px;
  font-weight: bold;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: -10px;

  .ant-tag {
    margin-right: 10px;
    margin-bottom: 10px;
    height: auto;
  }

}

.progress-bar {
  padding-right: 60px;
  width: 100%;
}

.upload-icon {
  position: absolute;
  top: 70px;
  opacity: 0.75;
  left: 70px;
  font-size: 0.75em;
  padding: 0.25rem;
  background: rgba(247, 245, 245, 0.767);
  border-radius: 50%;
  border: 1px solid rgba(177, 177, 177, 0.788);
}
.noMarginBottom {
  margin-bottom: 0
}
.setWidth {
  width: 825px;
}
</style>
